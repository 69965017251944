import axios from "axios";
import { $useRemoteApi } from "@/plugins/globalProperties";
import { $shareUrlBase } from "@/plugins/globalProperties";
export const shareInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production" || $useRemoteApi
      ? "https://sinkit.graybay-4c50acea.northeurope.azurecontainerapps.io/"
      : "https://localhost:7265/",
  json: true,
});

export const getShare = async (shareId) => {
  return await shareInstance.get(`/shares/${shareId}`);
};

export const updateShare = async (share) => {
  return await shareInstance.put("/shares/", share);
};

export const hitShare = async (shareId) => {
  return await shareInstance.put(`/shares/${shareId}/hit`);
};

export const generateHmac = async (shareId) => {
  return await shareInstance.post(`/shares/${shareId}/hmac`);
};

export const validateHmac = async (shareId, hmac) => {
  const model = { hmac: hmac };
  return await shareInstance.put(`/shares/${shareId}/hmac`, model);
};

export const generateUrl = async (shareId) => {
  const result = await generateHmac(shareId);
  const hmac = result.data;

  if (process.env.NODE_ENV === "development") {
    console.log(`Final URL: ${$shareUrlBase}${shareId}?hmac=${hmac}`);
  }

  return `${$shareUrlBase}${shareId}?hmac=${hmac}`;
};
