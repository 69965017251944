<template>
  <v-container class="my-16 py-16">
    <h2 class="text-center text-h2">Privacy Policy</h2>

    <p>
      At <strong>Jellypics.com</strong>, we are committed to protecting your
      privacy. This policy explains what information we collect, how we use it,
      how it’s shared, and your rights regarding your data. We comply with
      applicable US and EU privacy laws, including the California Consumer
      Privacy Act (CCPA), the Children’s Online Privacy Protection Act (COPPA),
      and the EU General Data Protection Regulation (GDPR).
    </p>

    <h3>Data Collected</h3>
    <p>
      We collect personal and usage information when you use Jellypics,
      including:
    </p>
    <ul>
      <li>
        <strong>Email addresses:</strong> For account creation, login, and
        communication.
      </li>
      <li>
        <strong>Images:</strong> Photos or other images you upload to your
        account.
      </li>
      <li>
        <strong>IP addresses:</strong> Logged for security, fraud prevention,
        and analytics purposes.
      </li>
      <li>
        <strong>Cookies:</strong> Small files stored on your device to remember
        preferences and track sessions.
      </li>
      <li>
        <strong>Analytics data:</strong> Information about how you use the site
        (e.g., page views, clicks), often collected via third-party tools.
      </li>
    </ul>

    <h3>How We Use Your Data</h3>
    <p>The data we collect is used for the following purposes:</p>
    <ul>
      <li>
        <strong>Account Creation & Management:</strong> To create your account,
        authenticate you, and provide core services (such as allowing you to log
        in and manage your profile).
      </li>
      <li>
        <strong>Service Improvement:</strong> To analyze usage and performance
        so we can improve Jellypics features, content, and user experience.
      </li>
      <li>
        <strong>Communication:</strong> To send important account notifications
        or respond to your inquiries. (We won’t send marketing emails unless
        you’ve opted in.)
      </li>
      <li>
        <strong>Analytics:</strong> To gather aggregate data about site usage
        (via analytics tools) which helps us understand user behavior and
        preferences.
      </li>
      <li>
        <strong>Security:</strong> To monitor, prevent, and detect fraud or
        misuse of our services and to keep the platform safe.
      </li>
    </ul>

    <h3>Data Visibility</h3>
    <p>
      Your personal information on Jellypics is private by default. We do not
      display your email, IP address, or other personal details publicly. Images
      you upload are also kept private unless you choose to share them. If you
      decide to make an image public or share a link to it, only then will that
      image and any associated information be visible to others. You control who
      can see the content you upload.
    </p>

    <h3>Children’s Privacy</h3>
    <p>
      Jellypics is not intended for use by children. We do not knowingly collect
      personal information from anyone under the age of 13, in compliance with
      COPPA. If you are under 13, please do not use this site or submit any
      information. If you are between 13 and 16 years old, you should only use
      Jellypics with parental or guardian permission (as required by the GDPR
      for users in the EU). If we discover that we have collected data from a
      child under 13 (or under 16 in the EU without consent), we will delete
      that information. Parents or guardians who believe their child may have
      provided us with personal information can contact us to request its
      removal.
    </p>

    <h3>User Rights</h3>
    <p>
      In accordance with applicable data protection laws (like GDPR and CCPA),
      you have certain rights regarding your personal data. These include the
      right to:
    </p>
    <ul>
      <li>
        <strong>Access Your Data:</strong> You can request a copy of the
        personal information we hold about you.
      </li>
      <li>
        <strong>Correct Your Data:</strong> You can ask us to correct or update
        your personal information if it’s inaccurate or incomplete.
      </li>
      <li>
        <strong>Delete Your Data:</strong> You can request that we delete your
        personal data (the “right to be forgotten”), subject to certain
        exceptions (for example, if we are legally required to keep it).
      </li>
      <li>
        <strong>Withdraw Consent:</strong> If we are processing your information
        based on your consent (for instance, optional features or newsletters),
        you have the right to withdraw that consent at any time.
      </li>
      <li>
        <strong>Data Portability:</strong> Where applicable, you may request to
        receive your personal data in a commonly used, machine-readable format.
      </li>
      <li>
        <strong>Opt-Out of Sale of Personal Information:</strong> You have the
        right to tell us not to sell your personal data (CCPA). **Note:**
        Jellypics does <em>not</em> sell your personal information, but we
        include this right for transparency and compliance.
      </li>
    </ul>
    <p>
      You can exercise these rights at any time by contacting us (see the
      “Contact Information” section below). We will verify your identity (to
      protect your privacy) and respond to your request within the timeframe
      required by law.
    </p>

    <h3>Third-Party Sharing</h3>
    <p>
      We do not sell or rent your personal information to third parties. We only
      share data in the following circumstances:
    </p>
    <ul>
      <li>
        <strong>Service Providers:</strong> We may share information with
        trusted third-party vendors who help us operate our website and services
        (for example, cloud hosting providers, email delivery services, or
        customer support tools). These partners are bound by confidentiality and
        are only permitted to use the data for the purposes of providing
        services to us.
      </li>
      <li>
        <strong>Analytics:</strong> We use third-party analytics tools (such as
        Google Analytics) to help us understand how users use Jellypics. These
        tools may receive certain information (like IP address and usage data),
        but this data is used only to analyze and improve our service.
      </li>
      <li>
        <strong>Legal Compliance and Safety:</strong> We may disclose your
        information if required to do so by law or in response to valid legal
        requests (e.g., subpoenas or court orders). We may also share
        information if necessary to protect our rights, resolve disputes,
        prevent fraud or abuse, or ensure the safety of our users.
      </li>
    </ul>
    <p>
      Whenever we share data with third parties, we ensure they have proper
      privacy and security practices in place. They are not allowed to use your
      data for their own purposes unrelated to the service they are providing to
      us.
    </p>

    <h2>Cookies & Tracking Technologies</h2>
    <p>
      <strong>Use of Cookies:</strong> Jellypics uses cookies and similar
      tracking technologies to enhance your experience. Cookies are small text
      files placed on your device that help our site function and gather
      information. We use cookies to keep you logged in, remember your
      preferences (such as language or display settings), and collect analytics
      data about site traffic and usage patterns. Some cookies are essential for
      the website to work properly, while others are used for analytics and
      improvement purposes.
    </p>
    <p>
      <strong>Your Choices:</strong> You can control or disable cookies at any
      time through your web browser settings. Most browsers allow you to refuse
      new cookies, delete existing cookies, or alert you when new cookies are
      being sent. However, please note that if you disable or delete cookies,
      some features of Jellypics may not function as intended (for example, you
      might not stay logged in or some preferences might not be saved). We do
      not currently respond to “Do Not Track” signals, but we respect any cookie
      consent choices you make on our site. For analytics cookies, you may also
      use available tools (such as browser add-ons provided by Google to opt out
      of Google Analytics) if you wish to opt out of analytics tracking.
    </p>

    <h3>Security Measures</h3>
    <p>
      We take the security of your data seriously. Jellypics implements a
      variety of technical and organizational measures to protect your personal
      information from unauthorized access, disclosure, alteration, or
      destruction. These measures include using encryption (HTTPS/TLS) to secure
      data transmission between your browser and our servers, storing data on
      secure servers with firewalls, and limiting access to personal data to
      authorized personnel who need it to operate our service.
    </p>
    <p>
      We also regularly update our software and systems to address potential
      security vulnerabilities and conduct internal reviews of our data
      collection and storage practices. However, please understand that no
      method of transmission over the internet or electronic storage is 100%
      secure. While we strive to protect your personal data, we cannot guarantee
      its absolute security. We encourage you to use a strong, unique password
      for your Jellypics account and to contact us immediately if you suspect
      any unauthorized access to your account.
    </p>

    <h3>Contact Information</h3>
    <p>
      If you have any questions, concerns, or requests regarding this Privacy
      Policy or your personal data, please contact us at
      <a href="mailto:privacy@jellypics.com">privacy@jellypics.com</a>. You may
      also reach out to us by mail at the following address:
    </p>
    <p class="my-8">
      <strong>Jellypics Privacy Team</strong><br />
      123 Jellypics Lane, Suite 100<br />
      Tech City, CA 94000<br />
      United States
    </p>
    <p>
      We will be happy to assist you and will respond to privacy-related
      inquiries promptly.
    </p>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getHardPlan } from "@/api/sales.client";

const hardPlan = ref(null);

const fetchHardPlan = async () => {
  try {
    const response = await getHardPlan();
    hardPlan.value = response.data;
  } catch (error) {
    console.error("Error fetching hard plan:", error);
    throw error;
  }
};

onMounted(() => {
  fetchHardPlan();
});
</script>

<style scoped>
h2,
h3,
h4 {
  margin-top: 32px;
}
</style>
