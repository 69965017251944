<template>
  <v-expansion-panels
    v-if="showSelectionTipsBanner && selectionMode"
    style="padding: 2rem"
  >
    <v-expansion-panel variant="popout">
      <v-expansion-panel-title disable-icon-rotate>
        {{ t("container-browser.selection-tip-title") }}
        <template v-slot:actions>
          <v-btn @click="closeBanner" icon="mdi-close" size="24" />
        </template>
      </v-expansion-panel-title>

      <v-expansion-panel-text>
        <p>
          On both <b>Windows</b> and <b>Mac</b>, you can easily select multiple
          items using your keyboard and mouse:
          <br />
          <v-icon color="primary" class="mr-1">mdi-mouse</v-icon> Click on a
          single item to select it.
          <br />
          <v-icon color="primary" class="mr-1">mdi-keyboard-shift</v-icon>
          <kbd>Shift</kbd> + <kbd>Click</kbd> lets you select a range of items
          between two clicks.
          <br />
          <v-icon color="primary" class="mr-1">mdi-keyboard</v-icon>
          Use <kbd>Ctrl</kbd> + <kbd>Click</kbd> (Windows) or
          <kbd>Command</kbd> + <kbd>Click</kbd> (Mac) to select multiple
          individual items.
          <br />
          <v-icon color="primary" class="mr-1">mdi-rocket</v-icon>
          Combine these shortcuts to manage your selections quickly and
          efficiently!
        </p>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>

  <PleaseWait class="please-wait-positioning"></PleaseWait>

  <!-- Container for when we have no items -->
  <v-container
    class="d-flex align-center justify-center no-select"
    v-if="files.length === 0"
  >
    <v-empty-state
      image="https://vuetifyjs.b-cdn.net/docs/images/components/v-empty-state/astro-dog.svg"
      :text="t('container-browser.empty-view-tip-message')"
      :title="t('container-browser.no-photos-yet-lets-change-that')"
    >
    </v-empty-state>
  </v-container>

  <template v-if="files.length > 0">
    <ContainerBrowserHead
      v-model:viewMode="viewMode"
      v-model:sortOrder="sortOrder"
      v-model:search="searchQuery"
      v-model:showOnlyStarred="showOnlyStarred"
      v-model:showFileNames="showFileNames"
      v-bind:title="containerName"
    />

    <!-- Real container of the items -->
    <v-container
      id="container-browser"
      v-if="viewMode === 'grid' && filteredFilesList.length > 0"
      class="d-flex align-content-start flex-wrap no-select justify-center"
      @click.self="handleEmptyAreaClick"
    >
      <component
        v-for="(entry, index) in components"
        :id="entry.id"
        :key="index"
        :is="entry.component"
        v-bind="entry.props"
      >
        <template v-slot:default="{ item }">
          <FileTile
            :canBeSelected="selectionMode"
            :canBeStarred="allowStarring"
            :canBeDeleted="allowDelete"
            :canBeDownloaded="allowDownload"
            :item="item"
            :selected="selectedItems.some((i) => i.id === item.id)"
            :useDynamicSizing="useDynamicSizing"
            :starred="isStarred(item.block)"
            :showFileNames="showFileNames"
            @click="handleItemClick($event, item)"
            @star="handleStarClick(item)"
            @delete="handleDeleteClick(item)"
            @download="handleDownloadClick(item)"
            @select="handleSelectClick(item)"
            @deselect="handleDeselectClick(item)"
          />
        </template>
      </component>
    </v-container>
    <!-- And the container for the list view -->
    <v-container
      v-if="viewMode === 'list' && filteredFilesList.length > 0"
      @click.self="handleEmptyAreaClick"
    >
      <v-data-table-virtual
        v-model="selectedItems"
        v-model:search="searchQuery"
        :show-select="selectionMode"
        :items="filteredFilesList"
        :headers="headers"
        return-object
        item-key="id"
      >
        <template v-slot:item.id="{ item }">
          <v-container class="d-flex pa-0">
            <v-img
              :src="imageUrl(item.path, 300)"
              :srcset="generateImageSrcSet(item.path)"
              :sizes="generateImageSizes()"
              height="200"
              width="200"
              :alt="item.name"
              cover
              @click="handleItemClick($event, item)"
              style="cursor: hand"
            />
            <v-container class="d-flex flex-column">
              <span>{{ item.name }}</span>
              <span class="text-caption"
                >Original name: {{ item.originalName }}
              </span>
              <v-textarea
                v-if="allowDescriptionChanges"
                :label="t('description')"
                hint="Add a description"
                v-model="item.description"
                required
                :counter="400"
                @update:focused="
                  (focused) => updateDescriptionOnFocusOut(item, focused)
                "
                variant="plain"
                class="my-2"
              />
              <span v-else class="text-caption">{{ item.description }}</span>
            </v-container>
          </v-container>
        </template>
        <template v-slot:item.tags="{ item }">
          <TagSelector
            :viewerMode="viewerMode"
            :fileId="item.id"
            :tags="tags"
            :fileTags="fileTags"
          />
        </template>
        <template v-slot:item.stars="{ item }">
          <v-container class="d-flex align-center">
            <v-icon
              aria-label="Starred icon"
              aria-hidden="false"
              class="mr-1"
              size="small"
              :color="isStarred(item.block) ? 'error' : ''"
              :icon="isStarred(item.block) ? 'mdi-heart' : 'mdi-heart-outline'"
            />
          </v-container>
        </template>
      </v-data-table-virtual>
    </v-container>

    <v-container
      class="d-flex flex-column align-center justify-center no-select"
      v-if="hasStarredFiles"
    >
      <v-tooltip location="top">
        <template #activator="{ props }">
          <v-btn
            size="small"
            variant="plain"
            prepend-icon="mdi-download"
            @click="handleDownloadAllClick"
            v-bind="props"
          >
            {{ $t("container-browser.download-liked-photos-as-text") }}
          </v-btn>
        </template>
        <span v-html="t('container-browser.download-csv-tooltip')"> </span>
      </v-tooltip>

      <p class="text-caption">
        {{ files.length }} files, {{ calculateTotalSize(files) }}
        {{ $t("container-browser.data-retrieved-on") }}
        {{ getCurrentDateTime() }}
      </p>
    </v-container>

    <SelectionProperties
      v-if="!viewerMode"
      ref="selectionProperties"
      id="selection-properties"
      :items="selectedItems"
      :selectedItem="selectedItem"
      :containerName="containerName"
    ></SelectionProperties>
  </template>
</template>

<script setup>
import {
  ref,
  watch,
  computed,
  defineEmits,
  defineProps,
  defineModel,
  onMounted,
  getCurrentInstance,
  nextTick,
  onUnmounted,
  markRaw,
} from "vue";
import ContainerBrowserHead from "@/components/ContainerBrowserHead";
import PleaseWait from "@/components/PleaseWait";
import { useI18n } from "vue-i18n";
import FileTile from "@/components/tiles/FileTile";
import SelectionProperties from "@/components/SidePanels/SelectionProperties";
import { updateFileDescription } from "@/api/file.client";
import { imageUrl } from "@/api/image.client";
import {
  generateImageSizes,
  generateImageSrcSet,
} from "@/helpers/image.helper";
import { calculateTotalSize } from "@/plugins/utils";
import TagSelector from "./TagSelector.vue";
import { useTagStore } from "@/stores/tag.store";
import { storeToRefs } from "pinia";
import { useViewerStore } from "@/stores/viewer.store";
import { VVirtualScroll } from "vuetify/lib/components/index.mjs";

const { t } = useI18n();

// Get access at the this. objects
const { appContext } = getCurrentInstance();
const global = appContext.config.globalProperties;

const tagStore = useTagStore();
const tag = storeToRefs(tagStore);

const viewerStore = useViewerStore();
const viewer = storeToRefs(viewerStore);

const tags = computed(() => {
  return props.viewerMode ? viewer.tags.value : tag.tags.value;
});

const fileTags = computed(() => {
  return props.viewerMode ? viewer.fileTags.value : tag.fileTags.value;
});

const viewMode = ref("grid");
const sortOrder = ref("asc");
const searchQuery = ref("");
const showOnlyStarred = ref(false);
const showFileNames = ref(false);
const originalImageDescription = ref(null);

const files = defineModel("files", {
  type: Array,
  default: [],
});

const filteredFilesList = ref([]);
const components = ref([]);
const componentId = ref(0);

watch(
  files,
  () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });

    nextTick(() => {
      filteredFilesList.value = files.value;
    });
  },
  { immediate: true },
);

watch(
  filteredFilesList,
  (newVal) => {
    components.value = [];
    componentId.value += 1;
    if (newVal.length > 0) {
      nextTick(() => {
        components.value.push({
          component: markRaw(VVirtualScroll),
          props: { items: filteredFilesList, itemHeight: "1px" },
          id: `vvscroll-${componentId.value}`,
        });

        if (process.env.NODE_ENV === "development") {
          console.log("virtual scroll components array: ", components.value);
        }
      });
    }
  },
  { immediate: true },
);

const selectedItems = defineModel("selectedItems", {
  type: Array,
  default: [],
});

const selectedItem = computed(() => {
  return selectedItems.value.length > 0
    ? selectedItems.value[selectedItems.value.length - 1]
    : null;
});

const emit = defineEmits([
  "selectionChanged",
  "itemClick",
  "star",
  "delete",
  "download",
  "selectionBannerClosed",
]);

const props = defineProps({
  selectionMode: {
    type: Boolean,
    default: false,
  },
  showSelectionTipsBanner: {
    type: Boolean,
    default: false,
  },
  allowStarring: {
    type: Boolean,
    default: true,
  },
  allowDelete: {
    type: Boolean,
    default: true,
  },
  allowDownload: {
    type: Boolean,
    default: true,
  },
  allowDescriptionChanges: {
    type: Boolean,
    default: true,
  },
  useDynamicSizing: {
    type: Boolean,
    required: false,
    default: false,
  },
  stars: {
    type: Array,
    required: true,
  },
  containerName: {
    type: String,
    default: "",
  },
  viewerMode: {
    type: Boolean,
    default: false,
  },
});

// Watch for changes in the props
watch(
  () => props.selectionMode,
  (newVal) => {
    // Execute your code here
    if (newVal) {
      // Code to execute when selectionMode is true
    } else {
      selectedItems.value = [];
    }
  },
);

// eslint-disable-next-line no-unused-vars
watch(viewMode, (newVal, oldVal) => {
  if (process.env.NODE_ENV === "development") {
    console.log("View mode changed", viewMode.value);
  }

  if (newVal !== "grid" && newVal !== "list") {
    console.error(
      `Invalid viewMode: ${newVal}. It must be either "grid" or "list".`,
    );
  }
});

// eslint-disable-next-line no-unused-vars
watch(sortOrder, (newVal, oldVal) => {
  if (process.env.NODE_ENV === "development") {
    console.log("Sort order changed", sortOrder.value);
  }

  if (newVal !== "asc" && newVal !== "desc") {
    console.error(
      `Invalid sortOrder: ${newVal}. It must be either "asc" or "desc".`,
    );
    return;
  }

  files.value.sort((a, b) => {
    if (newVal === "asc") {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  });
});

// eslint-disable-next-line no-unused-vars
watch(searchQuery, (newVal, oldVal) => {
  filterView();
});

// eslint-disable-next-line no-unused-vars
watch(showOnlyStarred, (newVal, oldVal) => {
  filterView();
});

// Controls refs
const selectionProperties = ref(null);

const headers = [
  {
    title: t("container-browser.picture"),
    key: "id",
    sortable: true,
    align: "left",
  },
  {
    title: t("container-browser.tags"),
    key: "tags",
    sortable: false,
    align: "left",
  },
  {
    title: t("container-browser.size"),
    key: "size",
    value: "sizeAsString",
    align: "end",
  },
];

onMounted(() => {
  if (!props.viewerMode) {
    headers.push({
      title: t("container-browser.stars"),
      key: "stars",
      sortable: false,
      align: "end",
    });
  }

  // Listen for the copyLikedFileNames event
  window.addEventListener("copyLikedFileNames", () => {
    const fileNames = files.value.filter((file) => {
      return isStarred(file.block);
    });

    const fileNamesString = fileNames
      .map((file) => {
        return file.originalName.replace(/\.[^/.]+$/, "");
      })
      .join(",");

    navigator.clipboard.writeText(fileNamesString).then(() => {
      global.$inform(t("container-browser.liked-file-names-copied"));
    });
  });

  // Listen for the copyFileNames event
  window.addEventListener("copyFileNames", () => {
    const fileNames = files.value.map((file) => {
      return file.originalName.replace(/\.[^/.]+$/, "");
    });
    const fileNamesString = fileNames.join(",");
    navigator.clipboard.writeText(fileNamesString).then(() => {
      global.$inform(t("container-browser.file-names-copied"));
    });
  });
});

onUnmounted(() => {
  window.removeEventListener("copyLikedFileNames");
  window.removeEventListener("copyFileNames");
});

const filterView = () => {
  let filteredFiles = [];
  const query = searchQuery.value;

  if (query && query.length > 0) {
    filteredFiles = files.value.filter((item) => {
      return item.name?.toLowerCase().includes(query.toLowerCase());
    });
  } else {
    filteredFiles = files.value;
  }

  if (showOnlyStarred.value) {
    filteredFiles = filteredFiles.filter((item) => {
      return isStarred(item.block);
    });
  }

  nextTick(() => {
    filteredFilesList.value = [...filteredFiles];

    if (process.env.NODE_ENV === "development") {
      console.log("filtered files Length: ", filteredFilesList.value.length);
    }
  });
};

const handleStarClick = (item) => {
  if (process.env.NODE_ENV === "development") {
    console.debug("Star clicked", item);
  }

  emit("star", item);
};

const handleDeleteClick = (item) => {
  if (process.env.NODE_ENV === "development") {
    console.debug("Delete clicked", item);
  }

  emit("delete", item);
};

const handleDownloadClick = (item) => {
  if (process.env.NODE_ENV === "development") {
    console.debug("Download clicked", item);
  }

  emit("download", item);
};

const handleSelectClick = (item) => {
  if (process.env.NODE_ENV === "development") {
    console.debug("Select clicked", item);
  }

  handleCtrlClick(item);
};

const handleDeselectClick = (item) => {
  if (process.env.NODE_ENV === "development") {
    console.debug("DeSelect clicked", item);
  }

  handleCtrlClick(item);
};

// Event handlers
const handleItemClick = (event, item) => {
  if (!props.selectionMode) {
    // We're not in selection mode, emit the itemClick event
    emit("itemClick", event, item);
    return;
  }

  if (event.shiftKey) {
    handleShiftClick(item);
  } else if (event.ctrlKey || event.metaKey) {
    handleCtrlClick(item);
  } else {
    handleRegularClick(item);
  }
};

const updateDescriptionOnFocusOut = async (item, isFocused) => {
  if (!isFocused) {
    try {
      await updateFileDescription(item.id, item.description);
    } catch (ex) {
      item.description = originalImageDescription.value;
      console.error("Error on update file description: ", ex);
      global.$error("An error occurred while updating the file description.");
    }
  } else {
    originalImageDescription.value = item.description;
  }
};

const isStarred = (blockId) => {
  if (props.stars.length > 0) {
    return props.stars.find((s) => s.starredBlock === blockId) !== undefined;
  }

  return false;
};

const closeBanner = (event) => {
  event.stopPropagation();
  emit("selectionBannerClosed");
};

const handleEmptyAreaClick = () => {
  console.debug("Empty area clicked");
  selectedItems.value = [];
  emit("selectionChanged", selectedItems.value);
};

const handleShiftClick = (item) => {
  let newItems = [];
  const finalItems = [...selectedItems.value];
  if (selectedItems.value.length === 0) {
    newItems.push(item);
  } else {
    const lastSelectedItem = finalItems[finalItems.length - 1];
    const startIndex = props.files.findIndex(
      (i) => i.id === lastSelectedItem.id,
    );
    const endIndex = props.files.findIndex((i) => i.id === item.id);
    const range = [startIndex, endIndex].sort((a, b) => a - b);
    newItems = props.files.slice(range[0], range[1] + 1);
  }

  for (const element of newItems) {
    if (finalItems.findIndex((item) => item.id === element.id) === -1) {
      finalItems.push(element);
    }
  }

  selectedItems.value = finalItems;
  emit("selectionChanged", selectedItems.value);
};

const handleCtrlClick = (item) => {
  const tempItems = [...selectedItems.value];
  const existingIndex = tempItems.findIndex((i) => i.id === item.id);
  if (existingIndex !== -1) {
    tempItems.splice(existingIndex, 1);
  } else {
    tempItems.push(item);
  }
  selectedItems.value = tempItems;
  emit("selectionChanged", selectedItems.value);
};

const handleRegularClick = (item) => {
  if (selectedItems.value.length === 1) {
    if (selectedItems.value[0].id === item.id) {
      selectedItems.value = [];
    } else {
      selectedItems.value = [item];
    }
  } else {
    selectedItems.value = [item];
  }
  emit("selectionChanged", selectedItems.value);
};

const getCurrentDateTime = () => {
  return new Date().toLocaleString(undefined, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
};

const escapeFileName = (name) => {
  return name.replace(/[^a-z0-9]/gi, "_").toLowerCase();
};

const handleDownloadAllClick = () => {
  const starredFiles = files.value.filter((item) => {
    return isStarred(item.block);
  });

  const quenLikedCsvContent = starredFiles
    .map((file) => {
      return `${file.originalName.replace(/\.[^/.]+$/, "")}`;
    })
    .join(",");

  const fullFile = `Liked original file names:\n${quenLikedCsvContent}\n\nGenerated by www.JellyPic.com`;

  const blob = new Blob([fullFile], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  const escapedFileName = escapeFileName(props.containerName);
  link.setAttribute("href", url);
  link.setAttribute("download", escapedFileName + " file list.txt");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const hasStarredFiles = computed(() => {
  return files.value.some((item) => isStarred(item.block));
});
</script>

<style>
.please-wait-positioning {
  position: fixed;
  bottom: 0; /* Posizionamento in basso */
  left: 50%; /* Allineamento al centro orizzontale */
  transform: translateX(-50%); /* Corregge lo scostamento */
  z-index: 9999; /* Sopra tutti gli altri contenuti */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Opzionale se vuoi limitare la larghezza */
  pointer-events: none; /* Non interferire con i click */
}

/* overrid virtual-scroll component styles */
.v-virtual-scroll .v-virtual-scroll__container {
  width: 100% !important;
  display: flex !important;
  overflow-y: hidden !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
}
</style>
