<script setup>
import { useI18n } from "vue-i18n";
import { loadFonts } from "@/plugins/webfontloader"; // Adjust the import path accordingly
import { watch, onMounted } from "vue";

const { locale } = useI18n();

// Call this function to update fonts and Vuetify styles dynamically
const updateFont = async (newLocale) => {
  await loadFonts(newLocale);
  const fontFamily = newLocale === "vi" ? "Roboto" : "DM Sans";

  // Vuetify uses --v-font-family internally
  document.documentElement.style.setProperty("--v-font-family", fontFamily);
  // Also set lang attribute
  document.documentElement.lang = newLocale;
};

// Watch locale changes and apply updates
watch(locale, (newLocale) => {
  document.documentElement.lang = newLocale;
  updateFont(newLocale);
});

// On mounted, ensure fonts match the current locale
onMounted(() => {
  updateFont(locale.value);
});

// Existing locale switcher method
const setLocale = (newLocale) => {
  locale.value = newLocale;
};
</script>

<template>
  <v-list density="compact" v-model="$i18n.locale">
    <v-list-item
      v-for="locale in $i18n.availableLocales"
      :key="`locale-${locale}`"
      :value="locale"
      @click="setLocale(locale)"
    >
      <template v-slot:prepend>
        <v-img
          v-if="locale === 'en'"
          src="https://flagicons.lipis.dev/flags/4x3/gb.svg"
          width="24"
          height="16"
          class="mr-2"
        />
        <v-img
          v-if="locale === 'vi'"
          src="https://flagicons.lipis.dev/flags/4x3/vn.svg"
          width="24"
          height="16"
          class="mr-2"
        />
        <v-img
          v-if="locale === 'fr'"
          src="https://flagicons.lipis.dev/flags/4x3/fr.svg"
          width="24"
          height="16"
          class="mr-2"
        />
        <v-img
          v-if="locale === 'it'"
          src="https://flagicons.lipis.dev/flags/4x3/it.svg"
          width="24"
          height="16"
          class="mr-2"
        />
      </template>
      <v-list-item-title :value="locale" class="ml-6">
        {{ $t(`locale-selector.${locale}`) }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>
