import { defineStore } from "pinia";
import {
  getContainer,
  getContainerOwner,
  getFiles,
  getStars,
} from "@/api/container.client";
import { getShare } from "@/api/share.client";
import { getViewerFileTags, getViewerTags } from "@/api/tag.client";

export const useViewerStore = defineStore("viewer", {
  state: () => ({
    share: {},
    container: {},
    containerOwner: {},
    items: {},
    stars: {},
    tags: [],
    fileTags: [],
  }),

  getters: {
    getShare() {
      return this.share;
    },
    getContainer() {
      return this.container;
    },
    getItems() {
      return this.items;
    },
    getStars() {
      return this.stars;
    },
    getTags() {
      return this.tags;
    },
  },

  actions: {
    async loadData(shareId) {
      await this.loadShare(shareId);
      await this.loadContainer(this.share.container);
      await this.loadFiles(this.container.id);
      await this.loadStars(this.container.id);

      const fileIds = this.items.map((i) => i.id);
      await this.loadTags(fileIds);
      await this.loadFileTags(fileIds);

      await this.loadContainerOwner(this.share.container);
    },

    async loadShare(shareId) {
      try {
        if (process.env.NODE_ENV === "development") {
          console.log("LoadShare");
        }

        const result = await getShare(shareId);
        this.share = result.data;
        console.log("share: ", this.share);
      } catch (exception) {
        if (process.env.NODE_ENV === "development") {
          console.error("LoadShare: ", exception);
        }

        throw exception;
      }
    },

    async loadContainer(containerId) {
      try {
        if (process.env.NODE_ENV === "development") {
          console.log("LoadContainer");
        }

        const result = await getContainer(containerId);
        this.container = result.data;
      } catch (exception) {
        if (process.env.NODE_ENV === "development") {
          console.error("LoadContainer: ", exception);
        }

        throw exception;
      }
    },

    async loadFiles(containerId) {
      try {
        if (process.env.NODE_ENV === "development") {
          console.log("LoadFiles");
        }

        const result = await getFiles(containerId);
        this.items = result.data;
      } catch (exception) {
        if (process.env.NODE_ENV === "development") {
          console.error("LoadFiles: ", exception);
        }

        throw exception;
      }
    },

    async loadStars(containerId) {
      try {
        if (process.env.NODE_ENV === "development") {
          console.log("LoadStars");
        }

        const result = await getStars(containerId);
        this.stars = result.data;
      } catch (exception) {
        if (process.env.NODE_ENV === "development") {
          console.error("LoadStars: ", exception);
        }

        throw exception;
      }
    },

    async loadTags(fileIds) {
      try {
        if (process.env.NODE_ENV === "development") {
          console.log("LoadTags");
        }

        const result = await getViewerTags(fileIds);
        this.tags = result.data;
      } catch (exception) {
        if (process.env.NODE_ENV === "development") {
          console.error("LoadTags: ", exception);
        }

        throw exception;
      }
    },

    async loadFileTags(fileIds) {
      try {
        if (process.env.NODE_ENV === "development") {
          console.log("LoadFileTags");
        }

        const result = await getViewerFileTags(fileIds);
        this.fileTags = result.data;
      } catch (exception) {
        if (process.env.NODE_ENV === "development") {
          console.error("LoadFileTags: ", exception);
        }

        throw exception;
      }
    },

    async loadContainerOwner(containerId) {
      try {
        if (process.env.NODE_ENV === "development") {
          console.log("LoadContainerOwner");
        }

        const result = await getContainerOwner(containerId);
        this.containerOwner = result.data;
      } catch (exception) {
        if (process.env.NODE_ENV === "development") {
          console.error("LoadContainerOwner: ", exception);
        }

        throw exception;
      }
    },
  },
});
