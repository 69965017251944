<script setup>
import { onMounted, ref, nextTick, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useTheme } from "vuetify";

const theme = useTheme();

let previousTheme = null;

import AppLogo from "@/components/AppLogo.vue";

const router = useRouter();
const sectionInView = ref(false);

function navigateToLogin() {
  router.push({ name: "login" });
}

const adjustCardHeights = () => {
  const cols = document.querySelectorAll(".feature-col");
  let maxHeight = 0;
  cols.forEach((col) => {
    const firstElement = col.firstElementChild;
    if (firstElement) {
      const height = firstElement.offsetHeight;
      if (height > maxHeight) {
        maxHeight = height;
      }
    }
  });
  cols.forEach((col) => {
    const firstElement = col.firstElementChild;
    if (firstElement) {
      firstElement.style.height = `${maxHeight}px`;
    }
  });
};

onMounted(() => {
  // Store the current theme so it can be restored later
  previousTheme = theme.global.name.value;
  // Force the landing page to use the light theme
  theme.global.name.value = "light";

  const observer = new IntersectionObserver(
    ([entry]) => {
      sectionInView.value = entry.isIntersecting;
      const section = document.querySelector(".section-2");
      if (section) {
        if (entry.isIntersecting) {
          section.classList.add("in-view");
        } else {
          section.classList.remove("in-view");
        }
      }
    },
    { threshold: 0.1 },
  );

  const section = document.querySelector(".section-2");
  if (section) {
    observer.observe(section);
  }

  nextTick(adjustCardHeights);
  window.addEventListener("resize", adjustCardHeights);
});

onUnmounted(() => {
  window.removeEventListener("resize", adjustCardHeights);
  // Revert back to the original theme when leaving the landing page
  theme.global.name.value = previousTheme;
});

import { fileContactRequest } from "@/api/sales.client.js";

const name = ref("");
const email = ref("");
const message = ref("");
const errors = ref({});

const validateForm = () => {
  errors.value = {};
  if (!name.value) errors.value.name = "Name is required";
  if (!email.value) errors.value.email = "Email is required";
  if (!message.value) errors.value.message = "Message is required";
  return Object.keys(errors.value).length === 0;
};

const submitForm = async () => {
  if (validateForm()) {
    const contactRequest = {
      userMail: email.value,
      name: name.value,
      message: message.value,
    };
    try {
      await fileContactRequest(contactRequest);
      if (process.env.NODE_ENV !== "production") {
        console.log("Contact request sent successfully");
      }
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.error("Error sending contact request:", error);
      }
    }
  }
};
</script>

<template>
  <!-- Hero banner -->
  <v-container fluid class="landing-main_container">
    <section
      class="hero_section px-0 height-100vh"
      style="background-color: transparent"
    >
      <div class="height-100 section-1_container">
        <div class="blur-container" style="--blur: 12vw">
          <div
            class="shape"
            style="
              --path: polygon(
                50.9% 37.2%,
                43.5% 34.7%,
                33.6% 26.1%,
                39.2% 10.8%,
                26.2% 0%,
                4.8% 6.4%,
                0% 30.4%,
                20.7% 37.2%,
                33.4% 26.3%,
                43.2% 34.9%,
                45% 35.6%,
                43.6% 46.4%,
                37.8% 59.5%,
                21.8% 63.2%,
                11.7% 76.1%,
                22.9% 91.3%,
                47.4% 91.3%,
                54% 79%,
                38% 59.6%,
                43.9% 46.4%,
                45.2% 35.5%,
                50.9% 37.6%,
                56.1% 36.8%,
                59.8% 47.6%,
                70.3% 61.9%,
                87.7% 56%,
                96.4% 37.4%,
                88.6% 15.1%,
                63.7% 16.7%,
                55.2% 33.6%,
                55.9% 36.6%,
                50.9% 37.2%
              );
            "
          ></div>

          <div
            class="shape"
            style="
              --path: polygon(
                50.9% 37.2%,
                43.5% 34.7%,
                33.6% 26.1%,
                39.2% 10.8%,
                26.2% 0%,
                4.8% 6.4%,
                0% 30.4%,
                20.7% 37.2%,
                33.4% 26.3%,
                43.2% 34.9%,
                45% 35.6%,
                43.6% 46.4%,
                37.8% 59.5%,
                21.8% 63.2%,
                11.7% 76.1%,
                22.9% 91.3%,
                47.4% 91.3%,
                54% 79%,
                38% 59.6%,
                43.9% 46.4%,
                45.2% 35.5%,
                50.9% 37.6%,
                56.1% 36.8%,
                59.8% 47.6%,
                70.3% 61.9%,
                87.7% 56%,
                96.4% 37.4%,
                88.6% 15.1%,
                63.7% 16.7%,
                55.2% 33.6%,
                55.9% 36.6%,
                50.9% 37.2%
              );
              --offset: 180deg;
              --speed: 6000ms;
              --background: linear-gradient(cyan, blue, green, purple, cyan);
            "
          ></div>
        </div>
        <v-sheet class="sheet-no_background">
          <v-container class="d-flex align-center justify-center">
            <AppLogo alwaysshowtext />
          </v-container>

          <v-row>
            <v-col cols="12" class="text-center">
              <div class="text-h1 font-weight-light">
                {{ $t("landing-page.hero-line-one") }}<br />{{
                  $t("landing-page.hero-line-two")
                }}
              </div>
              <div
                class="text-h6 my-4"
                v-html="$t('landing-page.hero-subtitle-paragraph')"
              ></div>
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn
                color="primary"
                size="x-large"
                class="mx-2 px-12 gradient-button"
                rounded="lg"
                @click="navigateToLogin"
                >{{ $t("landing-page.get-jellypic-free") }}</v-btn
              >
              <div class="text-overline mt-4 feature-block">
                <span class="mx-1">
                  {{
                    $t(
                      "landing-page.easy-log-in-no-credit-card-or-registration-1",
                    )
                  }}
                </span>
                <span class="mx-1">
                  {{
                    $t(
                      "landing-page.easy-log-in-no-credit-card-or-registration-2",
                    )
                  }}
                </span>
                <span class="mx-1">
                  {{
                    $t(
                      "landing-page.easy-log-in-no-credit-card-or-registration-3",
                    )
                  }}
                </span>
              </div>
            </v-col>
          </v-row>
        </v-sheet>
      </div>
    </section>
    <section
      id="landing-features"
      class="hero_section section-2"
      :class="{ 'in-view': sectionInView }"
    >
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto" elevation="16" variant="plain" hover>
            <div class="text-h2 text-center py-8">
              {{ $t("landing-page.features-title") }}
            </div>
            <p
              class="text-h5 text-center"
              v-html="$t('landing-page.features-hero-paragraph-one')"
            ></p>
            <p
              class="text-h5 text-center"
              v-html="$t('landing-page.features-hero-paragraph-two')"
            ></p>
            <v-card-text>
              <v-row class="pa-8">
                <v-col cols="12" sm="6" md="4" class="feature-col">
                  <v-card
                    elevation="0"
                    style="border: 1px solid #a9a9a9; background-color: white"
                  >
                    <v-card-text>
                      <v-icon
                        icon="mdi-link-lock"
                        size="x-large"
                        class="my-4"
                      ></v-icon>
                      <div class="feature-title">
                        {{
                          $t("landing-page.features-share-your-projects-title")
                        }}
                      </div>
                      <div class="feature-text">
                        {{
                          $t(
                            "landing-page.features-share-your-projects-message",
                          )
                        }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="feature-col">
                  <v-card
                    elevation="0"
                    style="border: 1px solid #a9a9a9; background-color: white"
                  >
                    <v-card-text>
                      <v-icon
                        icon="mdi-eye-outline"
                        size="x-large"
                        class="my-4"
                      ></v-icon>
                      <div class="feature-title">
                        {{ $t("landing-page.features-engage-title") }}
                      </div>
                      <div class="feature-text">
                        {{ $t("landing-page.features-engage-message") }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="feature-col">
                  <v-card
                    elevation="0"
                    style="border: 1px solid #a9a9a9; background-color: white"
                  >
                    <v-card-text>
                      <v-icon
                        icon="mdi-shield-lock-outline"
                        size="x-large"
                        class="my-4"
                      ></v-icon>
                      <div class="feature-title">
                        {{ $t("landing-page.features-no-AI-scraping-title") }}
                      </div>
                      <div
                        class="feature-text"
                        v-html="
                          $t('landing-page.features-no-AI-scraping-message')
                        "
                      ></div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="feature-col">
                  <v-card
                    elevation="0"
                    style="border: 1px solid #a9a9a9; background-color: white"
                  >
                    <v-card-text>
                      <v-icon
                        icon="mdi-account-lock-outline"
                        size="x-large"
                        class="my-4"
                      ></v-icon>
                      <div class="feature-title">
                        {{ $t("landing-page.features-gain-insights-title") }}
                      </div>
                      <div class="feature-text">
                        {{ $t("landing-page.features-gain-insishts-message") }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="feature-col">
                  <v-card
                    elevation="0"
                    style="border: 1px solid #a9a9a9; background-color: white"
                  >
                    <v-card-text>
                      <v-icon
                        icon="mdi-timer-off-outline"
                        size="x-large"
                        class="my-4"
                      ></v-icon>
                      <div class="feature-title">
                        {{
                          $t("landing-page.features-you-are-in-control-title")
                        }}
                      </div>
                      <div class="feature-text">
                        {{
                          $t("landing-page.features-you-are-in-control-message")
                        }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="feature-col">
                  <v-card
                    elevation="0"
                    style="border: 1px solid #a9a9a9; background-color: white"
                  >
                    <v-card-text>
                      <v-icon
                        icon="mdi-eye-outline"
                        size="x-large"
                        class="my-4"
                      ></v-icon>
                      <div class="feature-title">
                        {{
                          $t(
                            "landing-page.features-full-features-in-free-plan-title",
                          )
                        }}
                      </div>
                      <div class="feature-text">
                        {{
                          $t(
                            "landing-page.features-full-features-in-free-plan-message",
                          )
                        }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <!-- Sub legal notes-->
                <v-col cols="12" class="py-8">
                  <p class="text-caption">
                    {{ $t("landing-page.features-list-notes-one") }}
                  </p>
                  <p class="text-caption">
                    {{ $t("landing-page.features-list-notes-two") }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </section>

    <section id="landing-about" class="hero_section section-3">
      <v-row>
        <v-col cols="12">
          <v-card class="mx-auto" elevation="0">
            <div class="text-h2 text-center py-8">
              {{ $t("landing-page.about-jellypic-hero") }}
            </div>
            <v-card-text>
              <p
                class="text-h6"
                v-html="$t('landing-page.about-first-paragraph')"
              ></p>
              <p
                class="text-h6 my-4"
                v-html="$t('landing-page.about-second-paragraph')"
              ></p>
              <h6
                class="text-h6"
                v-html="$t('landing-page.about-third-paragraph')"
              ></h6>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card elevation="0" class="ma-3 pa-3" variant="outlined" hover>
            <v-card-text>
              <p class="text-h5">
                {{ $t("landing-page.about-main-message") }}
              </p></v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="12" class="text-center my-8">
          <v-btn
            color="primary"
            size="x-large"
            class="mx-2 px-12 gradient-button"
            rounded="lg"
            @click="navigateToLogin"
            >{{ $t("landing-page.get-jellypic-free") }}</v-btn
          >
        </v-col>
      </v-row>
    </section>

    <section class="hero_section section-4 px-0" id="landing-contact">
      <v-sheet>
        <v-row class="pa-10">
          <v-col cols="12">
            <v-card elevation="0">
              <div class="text-h2 text-center">
                {{ $t("landing-page.contact-us-title") }}
              </div>
              <div
                class="text-h5 text-center pt-2"
                v-html="$t('landing-page.contact-us-message')"
              ></div>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-form @submit.prevent="submitForm">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="name"
                    label="Name"
                    :error-messages="errors.name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="email"
                    label="Email"
                    :error-messages="errors.email"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-textarea
                v-model="message"
                label="Message"
                :error-messages="errors.message"
                required
              ></v-textarea>
              <v-btn type="submit" color="primary">Submit</v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-sheet>
    </section>
  </v-container>
</template>

<style scoped lang="scss">
.landing-main_container {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 0;
}

.main_container::-webkit-scrollbar {
  display: none;
}

section {
  scroll-snap-align: start;
}

@media (max-width: 1024px) {
  .text-h1 {
    font-size: 4rem !important;
  }
}

@media (max-width: 767px) {
  .text-h1 {
    font-size: 3rem !important;
  }

  .text-h2 {
    font-size: 3rem !important;
  }

  .text-h5 {
    font-size: 1rem !important;
  }

  .text-h6 {
    font-size: 1rem !important;
  }

  .height-100 {
    height: 100%;
  }

  .height-100vh {
    height: 100vh;
  }

  .section-1_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .sheet-no_background {
    background: unset !important;
  }

  .text-overline {
    line-height: 1.75 !important;
  }
}

.feature-block {
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
}

.hero_section {
  scroll-snap-align: start;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  position: relative;
  background-color: #f2f2f6;
  @media (min-width: 768px) {
    height: 100vh;
  }
}

/* Features section */
.section-2 {
  background-color: transparent !important;
  @media (min-width: 960px) {
    margin-left: 10vw;
    margin-right: 10vw;
  }
  @media (max-width: 959px) {
    margin-left: 0;
    margin-right: 0;
  }
}

.feature-title {
  font-size: 1.1rem;
  font-weight: 600;
}

.feature-text {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0.5rem;
}

/* About section */
.section-3 {
  background-color: transparent !important;
  @media (min-width: 960px) {
    margin-left: 10vw;
    margin-right: 10vw;
  }
  @media (max-width: 959px) {
    margin-left: 0;
    margin-right: 0;
  }
}

/* Contacts */
.section-4 {
  background-color: transparent !important;
  @media (min-width: 960px) {
    margin-left: 10vw;
    margin-right: 10vw;
  }
  @media (max-width: 959px) {
    margin-left: 0;
    margin-right: 0;
  }
}

.blur-container {
  overflow: hidden;
  --blur: 80px;
  opacity: 0.5;
  filter: blur(var(--blur));
  height: 100vh;
  width: 100%;
  position: absolute;
  display: grid;
  > * {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }
}
.shape {
  margin: 0 auto;
  aspect-ratio: 1;
  position: absolute;

  --width: 100%;
  --scale: 1;
  --opacity: 0.66;
  --top: 0;
  --left: 0;
  --path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  --background: linear-gradient(hotpink, red, orange, yellow, hotpink);

  --offset: 0deg;
  --speed: 60000ms;

  clip-path: var(--path);
  background: var(--background);
  scale: var(--scale);
  opacity: var(--opacity);
  width: var(--width);
  top: var(--top);
  left: var(--left);
  rotate: var(--offset);

  mix-blend-mode: difference;

  animation: turn var(--speed) linear forwards infinite;

  @keyframes turn {
    to {
      rotate: calc(var(--offset) + 1turn);
    }
  }
}

.horizontal-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.gradient-button {
  background: linear-gradient(45deg, #005f73, #0a9396, #94d2bd, #005f73);
  background-size: 200% 200%;
  animation: gradient-animation 7s ease infinite;
  border: none;
  color: white;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 50% 100%;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradient-text {
  /*background: linear-gradient(45deg, #a8e6cf, #dcedc1, #ffd3b6, #ffaaa5, #a8e6cf);
  background-size: 200% 200%;
  animation: gradient-animation 5s ease infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;*/
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
