<template>
  <v-container class="d-flex align-center justify-center fill-height">
    <div class="blur-container" style="--blur: 12vw">
      <div
        class="shape"
        style="
          --path: polygon(
            50.9% 37.2%,
            43.5% 34.7%,
            33.6% 26.1%,
            39.2% 10.8%,
            26.2% 0%,
            4.8% 6.4%,
            0% 30.4%,
            20.7% 37.2%,
            33.4% 26.3%,
            43.2% 34.9%,
            45% 35.6%,
            43.6% 46.4%,
            37.8% 59.5%,
            21.8% 63.2%,
            11.7% 76.1%,
            22.9% 91.3%,
            47.4% 91.3%,
            54% 79%,
            38% 59.6%,
            43.9% 46.4%,
            45.2% 35.5%,
            50.9% 37.6%,
            56.1% 36.8%,
            59.8% 47.6%,
            70.3% 61.9%,
            87.7% 56%,
            96.4% 37.4%,
            88.6% 15.1%,
            63.7% 16.7%,
            55.2% 33.6%,
            55.9% 36.6%,
            50.9% 37.2%
          );
        "
      ></div>
      <div
        class="shape"
        style="
          --path: polygon(
            50.9% 37.2%,
            43.5% 34.7%,
            33.6% 26.1%,
            39.2% 10.8%,
            26.2% 0%,
            4.8% 6.4%,
            0% 30.4%,
            20.7% 37.2%,
            33.4% 26.3%,
            43.2% 34.9%,
            45% 35.6%,
            43.6% 46.4%,
            37.8% 59.5%,
            21.8% 63.2%,
            11.7% 76.1%,
            22.9% 91.3%,
            47.4% 91.3%,
            54% 79%,
            38% 59.6%,
            43.9% 46.4%,
            45.2% 35.5%,
            50.9% 37.6%,
            56.1% 36.8%,
            59.8% 47.6%,
            70.3% 61.9%,
            87.7% 56%,
            96.4% 37.4%,
            88.6% 15.1%,
            63.7% 16.7%,
            55.2% 33.6%,
            55.9% 36.6%,
            50.9% 37.2%
          );
          --offset: 180deg;
          --speed: 6000ms;
          --background: linear-gradient(cyan, blue, green, purple, cyan);
        "
      ></div>
    </div>
    <v-container
      class="pb-0"
      :class="{
        'login-card-dark': theme.global.name.value === 'dark',
        'login-card-light': theme.global.name.value === 'light',
      }"
    >
      <template v-if="containerShareFound">
        <!-- Share details row -->
        <v-row dense>
          <v-col cols="12">
            <v-container class="d-flex flex-column align-center justify-center">
              <v-avatar :image="containerOwnerAvatar" size="80"></v-avatar>
              <p class="ma-4 text-center">
                {{ containerShare.name }}
              </p>
              <p class="text-center text-caption">
                {{ containerShare.description }}
              </p>
            </v-container>
          </v-col>
        </v-row>

        <!-- A pin is requested to access -->
        <v-row dense v-if="showPin">
          <v-col
            cols="12"
            class="d-flex alight-center justify-center flex-column"
          >
            <p class="text-center">
              {{ $t("collection-viewer-landing.pin-request-message") }}
            </p>
            <v-otp-input
              v-model="otpInput"
              :length="8"
              :autofocus="true"
              variant="solo-filled"
            ></v-otp-input>
          </v-col>
        </v-row>

        <!-- Login row -->
        <v-row dense v-if="showLogin && !hasErrors">
          <v-col
            cols="12"
            class="d-flex align-center flex-column justify-center"
          >
            <template v-if="authStore.hasToken">
              <!-- Show a simple login button the user already has a token -->
              <v-btn rounded="xl" elevation="4" @click="googleLogin">
                <template v-slot:prepend>
                  <v-icon>mdi-google</v-icon>
                </template>
                {{ $t("collection-viewer-landing.continueWithGoogle") }}</v-btn
              >
              <p class="text-caption my-8">
                {{ $t("collection-viewer-landing.protected-by-recaptcha") }}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  style="text-decoration: none"
                  >Google's Privacy Policy</a
                >
                and
                <a
                  href="https://policies.google.com/terms"
                  target="_blank"
                  style="text-decoration: none"
                  >Google's Terms of Service</a
                >
                apply.
              </p>
            </template>
            <template v-else>
              <!-- Here we need to identify the user -->
              <GoogleLogin
                :callback="loginCallback"
                :error="loginError"
                popup-type="CODE"
              >
                <v-btn rounded="xl" elevation="4">
                  <template v-slot:prepend>
                    <v-icon>mdi-google</v-icon>
                  </template>
                  {{ $t("collection-viewer-landing.login-with-google") }}</v-btn
                >
              </GoogleLogin>
              &nbsp;
              <v-btn
                rounded="xl"
                elevation="4"
                @click="anonumousLogin"
                v-if="containerShare.allowAnonymousAccess"
              >
                <template v-slot:prepend>
                  <v-icon>mdi-incognito</v-icon>
                </template>
                {{ $t("collection-viewer-landing.view-anonymously") }}</v-btn
              >
            </template>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" class="d-flex align-center justify-center">
            $t('collection-viewer-landing.login-error-message')
          </v-col>
        </v-row>
      </template>
      <template v-if="showCountdown">
        <!-- Countdown row -->
        <v-row dense>
          <v-col
            cols="12"
            class="d-flex flex-column align-center justify-center"
          >
            <p class="text-center text-overline">
              {{ $t("collection-viewer-landing.countdown-message") }}
            </p>
            <Countdown
              mainColor="silver"
              secondFlipColor="silver"
              :deadline="getDeadline()"
            />
          </v-col>
        </v-row>
      </template>
      <template v-if="!loading && !containerShareFound && !showCountdown">
        <v-row>
          <v-col
            cols="12"
            class="d-flex flex-column align-center justify-center"
          >
            <v-empty-state
              :action-text="t('collection-viewer-landing.go-back-to-home')"
              @click:action="goBackToHomepage"
              image="https://vuetifyjs.b-cdn.net/docs/images/components/v-empty-state/astro-dog.svg"
              :text="
                t('collection-viewer-landing.share-does-not-exists-message')
              "
              :title="t('collection-viewer-landing.no-share-to-show-here')"
            ></v-empty-state>
          </v-col>
        </v-row>
      </template>

      <!-- Loading row -->
      <template v-if="loading">
        <v-row>
          <v-col
            cols="12"
            class="d-flex flex-column align-center justify-center"
          >
            <p class="text-center text-overline">
              {{ $t("collection-viewer-landing.loading-share-message") }}
            </p>
            <v-progress-circular
              class="my-8"
              :size="70"
              :width="7"
              color="green"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </template>

      <!-- Footer row -->
      <v-row dense>
        <v-col cols="12" class="d-flex flex-column align-center">
          <AppLogo class="ma-4"></AppLogo>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <p class="my-16 text-center text-caption">
        <span class="text-on-surface text-opacity-80">Powered by </span>
        <a href="https://www.jellypics.com" target="_blank" class="text-primary"
          >Jellypic</a
        >
      </p>
    </v-container>
  </v-container>
</template>

<script setup>
import { getCurrentInstance, nextTick, ref, onMounted } from "vue";
// https://github.com/coskuncay/vue3-flip-countdown
import { Countdown } from "vue3-flip-countdown";
import { getShare } from "@/api/share.client";
import { validateReCaptcha } from "@/api/auth.client";
import {
  getContainerOwner,
  getContainerOwnerAvatar,
} from "@/api/container.client";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useTheme } from "vuetify";
import { useAuthStore } from "@/stores/auth.store";
import moment from "moment";
import AppLogo from "@/components/AppLogo.vue";
import { useReCaptcha } from "vue-recaptcha-v3";

const theme = useTheme();

const { t } = useI18n();
const route = useRoute();
const authStore = useAuthStore();

// Get access at the this. objects
const { appContext } = getCurrentInstance();
const global = appContext.config.globalProperties;

const { executeRecaptcha } = useReCaptcha();

// Local reactive state
const containerShare = ref({});
const containerShareFound = ref(false);
const loading = ref(true);
const showCountdown = ref(false);
const showLogin = ref(false);
const showPin = ref(false);
const containerOwnerAvatar = ref("");
const containerOwner = ref({});
const hasErrors = ref(false);
const sunriseDate = ref(null);

const otpInput = ref("");

onMounted(async () => {
  if (process.env.NODE_ENV === "development") {
    console.debug("Collection view langing page mounted");
  }

  const shareId = route.params.id;

  if (process.env.NODE_ENV === "development") {
    setTimeout(function () {
      loadShare(shareId);
    }, 500);
  } else {
    loadShare(shareId);
  }
});

const loadShare = async (shareId) => {
  try {
    const response = await getShare(shareId);
    if (response.status === 299) {
      showCountdown.value = true;
      sunriseDate.value = response.data.dateTime;

      return;
    }

    containerShare.value = response.data;
    containerShareFound.value = true;
    showLogin.value = true;

    try {
      const ownerResponse = await getContainerOwner(
        containerShare.value.container,
      );
      containerOwner.value = ownerResponse.data;
    } catch (ex) {
      console.error("Error fetching the container owner: ", ex);
    }

    try {
      const avatarResponse = await getContainerOwnerAvatar(
        containerShare.value.container,
      );
      containerOwnerAvatar.value = avatarResponse.data;
    } catch (ex) {
      console.error("Error fetching the container owner avatar: ", ex);

      if (
        ex.response &&
        (ex.response.status === 404 || ex.response.status === 500)
      ) {
        containerOwnerAvatar.value = `${window.location.origin}/img/failsafe-avatar.png`;
      }
    }

    if (containerShare.value.pin.length > 0) {
      showPin.value = true;
    }
  } catch (error) {
    if (error.response && error.response.status === 404) {
      containerShareFound.value = false;
    } else {
      if (process.env.NODE_ENV === "development") {
        console.error("Error fetching the share:", error);
      }

      throw error;
    }
  } finally {
    loading.value = false;
  }
};

function checkPin(pin) {
  if (process.env.NODE_ENV === "development") {
    console.info("Checking pin...");
  }

  const result = pin === containerShare.value.pin;

  if (result) {
    if (process.env.NODE_ENV === "development") {
      console.debug("Pin is correct");
    }
  } else {
    if (process.env.NODE_ENV === "development") {
      console.debug("Pin is incorrect");
    }
    global.$warn(t("collection-viewer-landing.invalid-pin-message"));

    otpInput.value = "";
  }

  return result;
}

function getDeadline() {
  return moment(String(sunriseDate.value)).format("YYYY-MM-DD HH:mm:ss");
}

function anonumousLogin() {
  if (process.env.NODE_ENV === "development") {
    console.log("Anonymous login");
  }
  if (!showPin.value) {
    if (process.env.NODE_ENV === "development") {
      console.debug("No pin required, redirecting to collection viewer");
    }
    gotoViewer();
  } else {
    if (process.env.NODE_ENV === "development") {
      console.info("Pin is required to view this collection");
    }
  }

  if (showPin.value && checkPin(otpInput.value)) {
    if (process.env.NODE_ENV === "development") {
      console.debug("Pin is correct, redirecting to collection viewer");
    }
    gotoViewer();
  } else {
    if (process.env.NODE_ENV === "development") {
      console.warn("Pin is incorrect");
    }
  }
}

async function googleLogin() {
  try {
    if (process.env.NODE_ENV === "development") {
      console.log("Google login");
    }

    const isPinValid = checkPin(otpInput.value);
    const isReCaptchaValid = await checkReCaptcha();

    if (!isReCaptchaValid) {
      global.$warn(t("collection-viewer-landing.human-verification-failed"));
      return;
    }

    // Note: the checkPin method will show a warning if the pin is incorrect
    if (showPin.value && isPinValid) {
      if (process.env.NODE_ENV === "development") {
        console.info("Pin is correct");
      }

      if (authStore.hasToken) {
        // Is registered so go ahead and redirect to the viewer. If not wait for the callback to redirect
        nextTick(() => {
          gotoViewer();
        });
      }
    } else if (showPin.value && !isPinValid) {
      if (process.env.NODE_ENV === "development") {
        console.debug("Pin is incorrect");
      }

      // Do nothing, just return, we show an error message in the checkPin method
      return;
    }

    // Here we're all set go ahead and redirect to the viewer
    gotoViewer();
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.error("Error during Google login", error);
    }
    global.$warn(
      t("collection-viewer-landing.an-error-occurred-during-google-login"),
    );
  }
}

async function loginCallback(value) {
  if (process.env.NODE_ENV === "development") {
    console.debug("Google login callback", value);
  }

  try {
    // Specify that the new user (if in the BE is a new user) will be a guest
    value.guest = true;

    await authStore.signInAsync(value);
  } catch (error) {
    if (error.isAxiosError && error.code === "ERR_NETWORK") {
      if (process.env.NODE_ENV === "development") {
        console.error("Network error occurred during sign-in:", error);
      }
      hasErrors.value = true;
    } else {
      if (process.env.NODE_ENV === "development") {
        console.error("An error occurred during sign-in:", error);
      }
      hasErrors.value = true;
    }
  }

  nextTick(() => {
    gotoViewer();
  });
}

async function checkReCaptcha() {
  // Esegui il reCAPTCHA e ottieni il token
  const token = await executeRecaptcha("submit_action");

  // Invia il token al backend per la verifica
  const response = await validateReCaptcha(token, authStore.user.name);

  const result = response.data.success;

  if (result) {
    if (process.env.NODE_ENV === "development") {
      console.debug("ReCaptcha verification successful");
    }
  } else {
    if (process.env.NODE_ENV === "development") {
      console.debug("ReCaptcha verification failed");
    }
  }

  return result;
}

function loginError(value) {
  hasErrors.value = true;
  if (process.env.NODE_ENV === "development") {
    console.error("Login error", value);
  }
}

function gotoViewer() {
  // Redirect to collection viewer
  global.$router.push({
    name: "collectionviewer",
    params: { id: route.params.id },
    query: { hmac: route.query.hmac },
  });
}

const goBackToHomepage = () => {
  global.$router.push({ name: "home" });
};
</script>

<style scoped>
.login-card-light {
  min-width: 400px;
  max-width: 600px;
  min-height: 300px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
}

.login-card-dark {
  min-width: 400px;
  max-width: 600px;
  min-height: 300px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  background-color: rgba(24, 24, 24, 0.9);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
}

.blur-container {
  overflow: hidden;
  --blur: 80px;
  opacity: 0.5;
  filter: blur(var(--blur));
  height: 100vh;
  width: 100%;
  position: absolute;
  display: grid;
  > * {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }
}
.shape {
  margin: 0 auto;
  aspect-ratio: 1;
  position: absolute;

  --width: 100%;
  --scale: 1;
  --opacity: 0.66;
  --top: 0;
  --left: 0;
  --path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  --background: linear-gradient(hotpink, red, orange, yellow, hotpink);

  --offset: 0deg;
  --speed: 60000ms;

  clip-path: var(--path);
  background: var(--background);
  scale: var(--scale);
  opacity: var(--opacity);
  width: var(--width);
  top: var(--top);
  left: var(--left);
  rotate: var(--offset);

  mix-blend-mode: difference;

  animation: turn var(--speed) linear forwards infinite;

  @keyframes turn {
    to {
      rotate: calc(var(--offset) + 1turn);
    }
  }
}
</style>
