<template>
  <div
    class="d-flex no-select align-center"
    style="max-width: 150px"
    @click="$router.push('/')"
  >
    <v-img src="@/assets/jellypic-symbol.svg" width="32" height="32" />
    <v-img
      :src="`img/jellypic-text-${isDarkTheme ? 'dark' : 'light'}.svg`"
      width="100"
      height="32"
      class="d-none d-sm-block"
      v-if="alwaysShowText"
    />
  </div>
</template>

<script setup>
import { onMounted, computed } from "vue";
import { useTheme } from "vuetify";

// props
const props = defineProps({
  alwaysShowText: {
    type: Boolean,
    default: false,
  },
});

// Vuetify theme
const theme = useTheme();
const isDarkTheme = computed(() => theme.global.current.value.dark);

// life cycle Hooks
onMounted(() => {});
</script>

<style scoped></style>
