import { createRouter, createWebHistory } from "vue-router";

import LandingPage from "../views/LandingPage.vue";
import LoginView from "@/views/LoginView.vue";
import NotFound from "@/views/NotFound.vue";
import ServerError from "@/views/ServerError.vue";
import FullScreenPage from "@/views/FullScreenPage.vue";
import CollectionViewerLanding from "@/views/CollectionViewerLanding.vue";
import CollectionViewer from "@/views/CollectionViewer.vue";
import { useAuthStore } from "@/stores/auth.store";
import ServiceTerms from "@/views/ServiceTerms.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import ServiceStatus from "@/views/ServiceStatus.vue";
import WorkspaceNotAvailableOnMobile from "@/views/WorkspaceNotAvailableOnMobile.vue";
// Global properties
import { $blockWorkspaceOnMobile } from "@/plugins/globalProperties";

const routes = [
  {
    path: "/",
    component: FullScreenPage,
    children: [
      {
        path: "/500",
        name: "ServerError",
        component: ServerError,
      },
      {
        path: "/service-terms",
        name: "ServiceTerms",
        component: ServiceTerms,
      },
      {
        path: "/health",
        name: "ServiceStatus",
        component: ServiceStatus,
      },
      {
        path: "/privacy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
      },
      {
        path: "/:pathMatch(.*)*", // Catch-all route for 404
        name: "NotFound",
        component: NotFound,
      },
      {
        path: "/login",
        name: "login",
        component: LoginView,
        meta: { requiresAuth: false, onlyAnonymous: true },
      },
      {
        path: "/workspace-not-available-on-mobile",
        name: "WorkspaceNotAvailableOnMobile",
        component: WorkspaceNotAvailableOnMobile,
        meta: { requiresAuth: true, onlyAnonymous: false },
      },
      {
        path: "",
        name: "home",
        component: LandingPage,
      },
    ],
  },
  {
    path: "/workspace",
    name: "workspace",
    // Route level code-splitting this generates a separate chunk (workspace.[hash].js) for this route (will be the biggest chunk)
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "workspace" */ "../views/AppWorkspace.vue"),
    meta: { requiresAuth: true, onlyAnonymous: false },
  },
  {
    path: "/view/:id",
    children: [
      {
        path: "",
        name: "collectionviewerlanding",
        component: CollectionViewerLanding,
        meta: { requiresAuth: false, onlyAnonymous: false },
      },
      {
        path: "browser",
        name: "collectionviewer",
        component: CollectionViewer,
        meta: { requiresAuth: false, onlyAnonymous: false },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const isAuthenticated = authStore.hasToken;
  const isMobile = window.innerWidth < 768;

  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: "login" });
  } else if (to.meta.onlyAnonymous && isAuthenticated) {
    next({ name: "workspace" });
  } else if (to.name === "workspace" && isMobile && $blockWorkspaceOnMobile) {
    next({ name: "WorkspaceNotAvailableOnMobile" });
  } else {
    next();
  }
});

export default router;
