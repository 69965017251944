<template>
  <v-container class="fill d-flex align-center justify-center">
    <v-container class="login-card">
      <v-row>
        <v-row v-if="!hasLoggedIn">
          <v-col cols="12">
            <AppLogo class="d-flex align-center justify-center" />
          </v-col>
          <v-col cols="12" class="text-center">
            <span class="stack" style="--stacks: 3">
              <span style="--index: 0">500 Internal Server Error</span>
              <span style="--index: 1">500 Internal Server Error</span>
              <span style="--index: 2">500 Internal Server Error</span>
            </span>
            <p class="my-8">That's An Error. That's All We Know.</p>

            <v-btn variant="tonal" to="/" text>home</v-btn>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
  </v-container>
  <AppFooter></AppFooter>
</template>

<script setup></script>

<style scoped>
.fill {
  width: 100%;
  height: 100%;
}
.login-card {
  min-width: 400px;
  max-width: 600px;
  min-height: 300px;
  padding: 35px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(64px);
  color: black;
  background-color: rgba(255, 255, 255, 0.5);
}

.stack {
  display: grid;
  grid-template-columns: 1fr;
  font-size: 2em;
}

.stack span {
  font-weight: bold;
  grid-row-start: 1;
  grid-column-start: 1;
  --stack-height: calc(100% / var(--stacks) - 1px);
  --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
  --clip-top: calc(var(--stack-height) * var(--index));
  --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
  clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
  animation:
    stack 340ms cubic-bezier(0.46, 0.29, 0, 1.24) 1 backwards
      calc(var(--index) * 120ms),
    glitch 2s ease infinite 2s alternate-reverse;
}

.stack span:nth-child(odd) {
  --glitch-translate: 8px;
}
.stack span:nth-child(even) {
  --glitch-translate: -8px;
}

@keyframes stack {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    text-shadow:
      -2px 3px 0 red,
      2px -3px 0 blue;
  }
  60% {
    opacity: 0.5;
    transform: translateX(50%);
  }
  80% {
    transform: none;
    opacity: 1;
    text-shadow:
      2px -3px 0 red,
      -2px 3px 0 blue;
  }
  100% {
    text-shadow: none;
  }
}

@keyframes glitch {
  0% {
    text-shadow:
      -2px 3px 0 red,
      2px -3px 0 blue;
    transform: translate(var(--glitch-translate));
  }
  2% {
    text-shadow:
      2px -3px 0 red,
      -2px 3px 0 blue;
  }
  4%,
  100% {
    text-shadow: none;
    transform: none;
  }
}
</style>
