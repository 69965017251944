import { $useRemoteApi } from "@/plugins/globalProperties";
import axios from "axios";

export const settingsInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production" || $useRemoteApi
      ? "https://sinkit.graybay-4c50acea.northeurope.azurecontainerapps.io/"
      : "https://localhost:7265/",
  json: true,
});

export const getSettings = async () => {
  return await settingsInstance.get("/settings");
};

export const addSetting = async (setting) => {
  return await settingsInstance.post("/settings", setting);
};

export const updateSetting = async (setting) => {
  return await settingsInstance.put("/settings", setting);
};

export const deleteSetting = async (key) => {
  return await settingsInstance.delete(`/settings/${key}`);
};
