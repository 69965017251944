import { createI18n } from "vue-i18n";
import en from "./locales/en.json";
import vi from "./locales/vi.json";
import fr from "./locales/fr.json";
import it from "./locales/it.json";

const english = "en";
const vietnamese = "vi";
const french = "fr";
const italian = "it";

const localizationStrings = {
  [english]: en,
  [vietnamese]: vi,
  [french]: fr,
  [italian]: it,
};

export const configureI18n = () => {
  const browserLocale = navigator.language.split("-")[0];

  let locale = english;

  if (process.env.NODE_ENV === "production")
    locale = localizationStrings[browserLocale] ? browserLocale : english;

  const i18n = createI18n({
    locale: locale,
    fallbackLocale: english,
    messages: localizationStrings,
  });

  return i18n;
};
