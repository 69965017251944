/**
 * plugins/webfontloader.js
 *
 * webfontloader documentation: https://github.com/typekit/webfontloader
 */

// loadFonts.js
export async function loadFonts(locale) {
  const webFontLoader = await import(
    /* webpackChunkName: "webfontloader" */ "webfontloader"
  );

  const fontFamilies =
    locale === "vi" ? ["Roboto:300,400,500"] : ["DM Sans:300,400,500"];

  webFontLoader.load({
    google: {
      families: fontFamilies,
    },
  });
}
