import axios from "axios";
import { $useRemoteApi } from "@/plugins/globalProperties";

export const workspaceInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production" || $useRemoteApi
      ? "https://sinkit.graybay-4c50acea.northeurope.azurecontainerapps.io/"
      : "https://localhost:7265/",
  json: true,
});

export const hasWorkspace = async () => {
  return await workspaceInstance.get("/workspace/has");
};

export const hasCompeteProfile = async () => {
  return await workspaceInstance.get("/workspace/hascompleteprofile");
};

export const getWorkspace = async () => {
  return await workspaceInstance.get("/workspace");
};

export const getWorkspaceOwner = async () => {
  return await workspaceInstance.get("/workspace/owner");
};

export const getPictureAlbums = async (workspaceId) => {
  return await workspaceInstance.get(
    `/workspace/${workspaceId}/picture-albums`,
  );
};
