<template>
  <transition name="fade">
    <v-container v-if="isLoadingLocal">
      <v-container class="box px-4 py-2 text-caption text-center">
        {{ message }}
      </v-container>
    </v-container>
  </transition>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { isLoading } from "@/api"; // Import the global loading state
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const messages = [
  t("please-wait.im-working-please-be-patient"),
  t("please-wait.working-diligently-to-get-this-done"),
  t("please-wait.processing-your-request-please-be-patient"),
  t("please-wait.hold-on-were-almost-there"),
  t("please-wait.waiting-for-the-magic-to-happen"),
  t("please-wait.loading-your-data-just-a-moment"),
  t("please-wait.please-wait"),
  t("please-wait.hang-tight-were-getting-it-done"),
  t("please-wait.fetching-your-data-just-a-second"),
  t("please-wait.thinking-hard-about-your-request"),
  t("please-wait.crunching-the-numbers-hold-on"),
  t("please-wait.making-sure-everythings-perfect-for-you"),
  t("please-wait.patience-is-a-virtue-almost-there"),
  t("please-wait.loading-the-magic-one-moment"),
  t("please-wait.were-on-it-just-give-us-a-second"),
  t("please-wait.good-things-take-time-please-wait"),
  t("please-wait.your-request-is-in-progress-hang-tight"),
];
const message = ref("");
const isLoadingLocal = ref(false);

function changeMessage() {
  message.value = messages[Math.floor(Math.random() * messages.length)];
}

onMounted(() => {});

watch(isLoading, (newVal) => {
  if (newVal) {
    setTimeout(() => {
      if (isLoading.value) {
        changeMessage();
        isLoadingLocal.value = true;
      }
    }, 500);
  } else {
    isLoadingLocal.value = false;
  }
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.box {
  background: hsl(0, 0%, 100%);
  padding: 16px 24px;
  border-radius: 16px;
  color: black;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01);
  position: relative;
  max-width: 400px;

  &::after {
    position: absolute;
    content: "";
    top: 10px;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    transform: scale(1) translateZ(0);
    filter: blur(10px);
    background: linear-gradient(
      to left,
      #ff5770,
      #e4428d,
      #c42da8,
      #9e16c3,
      #6501de,
      #9e16c3,
      #c42da8,
      #e4428d,
      #ff5770
    );
    background-size: 200% 200%;
    animation: animateGlow 1.25s linear infinite;
  }
}

@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}
</style>
