<script setup>
import {
  onMounted,
  getCurrentInstance,
  ref,
  computed,
  watch,
  nextTick,
} from "vue";
import PhotoGallery from "@/components/PhotoGallery";
import ContainerBrowser from "@/components/ContainerBrowser";
import { hitShare, validateHmac } from "@/api/share.client";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/auth.store";
import { getContainerOwnerAvatar } from "@/api/container.client";
import { getBlockMetadataValue } from "@/api/block.client";
import { starBlock } from "@/api/interaction.client";
import { getImage } from "@/api/image.client";
import { saveAs } from "file-saver";
import { useViewerStore } from "@/stores/viewer.store";
import { storeToRefs } from "pinia";
import CollectionViewerTour from "@/components/Misc/CollectionViewerTour.vue";

const { t } = useI18n();
const route = useRoute();
const authStore = useAuthStore();

const viewerStore = useViewerStore();
const { share, container, items, stars, containerOwner } =
  storeToRefs(viewerStore);

// Get access at the this. objects
const { appContext } = getCurrentInstance();
const global = appContext.config.globalProperties;

// Local reactive state
const isAutheticated = ref(false);
const showBanner = ref(true);
const inError = ref(false);
const loaded = ref(false);
const currentFile = ref({});
const selectionMode = ref(false);
const containerOwnerAvatar = ref("");
const containerOwnerMotto = ref("");
const containerOwnerBio = ref("");
const containerOwnerInstaUrl = ref("");
const isFirstViewerVisit = ref(false);
// Component references
const photoGallery = ref(null);
const containerBrowser = ref(null);
const collectionViewerTour = ref({});

const formattedDescription = computed(() => {
  const result = share.value.description
    .split("\n")
    .map((line) => `<p class="mb-4 deco-paragraph">${line}</p>`)
    .join("");

  return result;
});

const formattedBio = computed(() => {
  const result = containerOwnerBio.value
    .split("\n")
    .map((line) => `<p class="mb-4 deco-paragraph">${line}</p>`)
    .join("");

  return result;
});

const formattedInstaUrl = computed(() => {
  return containerOwnerInstaUrl.value
    .replace(/^https?:\/\/(www\.)?/, "")
    .replace(/\/$/, "");
});

onMounted(async () => {
  console.debug("Collection view page mounted");

  const hmac = route.query.hmac;
  const shareId = route.params.id;

  try {
    isAutheticated.value = authStore.hasToken;
    showBanner.value = !authStore.hasToken;

    if (await validateShare(shareId, hmac)) {
      await viewerStore.loadData(shareId);
      await nextTick();

      try {
        const bioResponse = await getBlockMetadataValue(
          containerOwner.value.block,
          "profile",
          "bio",
        );
        containerOwnerBio.value = bioResponse.data;
      } catch (ex) {
        console.error(ex);
      }

      try {
        const mottoResponse = await getBlockMetadataValue(
          containerOwner.value.block,
          "profile",
          "motto",
        );
        containerOwnerMotto.value = mottoResponse.data;
      } catch (ex) {
        console.error(ex);
      }

      try {
        const instaResponse = await getBlockMetadataValue(
          containerOwner.value.block,
          "profile",
          "instagram-url",
        );
        containerOwnerInstaUrl.value = instaResponse.data;
      } catch (ex) {
        console.error(ex);
      }

      try {
        const avatarResponse = await getContainerOwnerAvatar(
          share.value.container,
        );
        containerOwnerAvatar.value = avatarResponse.data;
      } catch (ex) {
        console.error(ex);
        containerOwnerAvatar.value = `${window.location.origin}/img/failsafe-avatar.png`;
      }

      watch([share, containerOwner], ([newShare, newContainerOwner]) => {
        if (newShare.name && newContainerOwner.name) {
          document.title = `${newShare.name} by ${newContainerOwner.name} - ${global.$appName}`;
        }
      });

      // Now that all is done increase the hit count, but don't wait for it
      // just add some delay to be user the user can see the page
      setTimeout(() => {
        hitShare(shareId);
      }, 10000);

      await nextTick();

      loaded.value = true;

      // Check if the flag exists in localStorage
      if (!localStorage.getItem("isFirstViewerVisit")) {
        // If not, it's the first visit
        isFirstViewerVisit.value = true;
        localStorage.setItem("isFirstViewerVisit", "true"); // Set the flag
      } else {
        isFirstViewerVisit.value = false;
      }

      // Wait for DOM updates after the component is mounted
      await nextTick();

      if (isFirstViewerVisit.value) {
        setTimeout(() => {
          collectionViewerTour.value.startTour();
        }, 1000);
      }

      // Listen for the openGallery event
      window.addEventListener("openGallery", () => {
        if (
          (currentFile.value && currentFile.value.id) ||
          (items.value && items.value.length > 0)
        ) {
          openPhotoGallery(currentFile.value || items.value[0]);
        }
      });

      console.info("Viewer loaded successfully");
    } else {
      console.error("Viewer load failed due to HMAC verification failure");
    }
  } catch (exception) {
    console.error("Viewer load failed.", exception);
    global.$error(t("collection-viewer.viewer-load-error-message"));
  }
});

const validateShare = async (shareId, hmac) => {
  const result = await validateHmac(shareId, hmac);

  if (result.data) {
    console.info("HMAC verification successful");
    return true;
  } else {
    console.error("HMAC verification failed");
    inError.value = true;
    return false;
  }
};

const handleItemClick = (event, item) => {
  console.log("Item clicked", item);
  openPhotoGallery(item);
};

const handleStarClick = async (item) => {
  const response = await starBlock(item.block);
  if (response) {
    // starred
    const updatedStars = [...stars.value];
    updatedStars.push(response);
    stars.value = updatedStars;
  } else {
    // unstarred
    const updatedStars = [...stars.value];
    const indexToRemove = updatedStars.findIndex(
      (s) => s.starredBlock === item.block,
    );
    if (indexToRemove !== -1) {
      updatedStars.splice(indexToRemove, 1);
      stars.value = updatedStars;
    }
  }
};

const handleDownloadClick = async (item) => {
  const result = await getImage(item.id);
  saveAs(result.data, item.name);
};

const openPhotoGallery = (item) => {
  if (container.value === null) {
    global.$error(t("collection-viewer.no-container-selected-error-message"));
    return;
  }

  currentFile.value = item;
  console.info("Opening the photo gallery dialog");

  photoGallery.value.open();
};
</script>

<template>
  <v-container class="my-16">
    <v-container v-if="inError">
      <v-row>
        <v-col>
          <v-alert type="error" dismissible>
            {{ $t("collection-viewer.load-error-message") }}
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
    <template v-if="loaded">
      <v-banner
        icon="mdi-alert"
        color="warning"
        text="As an anonymous user, your experience is limited. Features such as commenting, rating, and downloading may not be available."
        :stacked="false"
        v-if="showBanner"
      >
        <template v-slot:actions>
          <v-btn text @click="showBanner = false">{{ $t("close") }}</v-btn>
        </template>
      </v-banner>

      <!-- Help button -->
      <v-btn
        variant="plain"
        @click="collectionViewerTour.startTour"
        style="position: absolute; top: 0; right: 0"
        class="ma-2"
        size="x-small"
      >
        {{ $t("collection-viewer.need-help") }}
      </v-btn>

      <!-- Share details row -->
      <v-container
        class="d-flex flex-column align-center justify-center text-center"
      >
        <v-avatar
          :image="containerOwnerAvatar"
          size="128"
          class="soft-drop-shadow"
        ></v-avatar>

        <p class="my-4">
          {{ share.name }}<br />
          by
          <b>{{ containerOwner.name }}</b>
        </p>

        <div
          class="divider div-transparent div-dot my-8"
          style="width: 50%"
        ></div>
      </v-container>
      <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
      <v-container v-html="formattedDescription"></v-container>
      <ContainerBrowser
        :viewerMode="true"
        v-model:files="items"
        :selectionMode="selectionMode"
        :allowStarring="isAutheticated && share.allowStarring"
        :allowDownload="share.allowDownloads"
        :allowDelete="false"
        :stars="stars"
        :allowDescriptionChanges="false"
        :showSelectionTipsBanner="false"
        
        @itemClick="handleItemClick"
        @star="handleStarClick"
        @download="handleDownloadClick"
        ref="containerBrowser"
      />

      <p class="text-center text-h5 mt-8">
        {{ containerOwner.name }}
      </p>
      <p class="text-center text-italic text-h6" v-if="containerOwnerMotto">
        “{{ containerOwnerMotto }}”
      </p>

      <div
        class="divider div-transparent my-8"
        style="width: 100%"
        v-if="containerOwnerInstaUrl || containerOwnerBio"
      ></div>

      <v-container
        class="my-8"
        v-if="containerOwnerBio"
        v-html="formattedBio"
      ></v-container>

      <p
        class="d-flex justify-center align-center text-center mt-8"
        v-if="containerOwnerInstaUrl"
      >
        {{ $t("collection-viewer.follow-me-on") }}
      </p>
      <p
        class="d-flex justify-center align-center text-center"
        v-if="containerOwnerInstaUrl"
      >
        <v-icon
          class="mx-2"
          style="
            background: linear-gradient(
              45deg,
              #f09433,
              #e6683c,
              #dc2743,
              #cc2366,
              #bc1888
            );
            -webkit-background-clip: text;
            color: transparent;
          "
          >mdi-instagram</v-icon
        >

        <a :href="containerOwnerInstaUrl" target="_blank">{{
          formattedInstaUrl
        }}</a>
      </p>

      <p class="my-16 text-center text-caption">
        <span class="text-on-surface text-opacity-80">Powered by </span>
        <a href="https://www.jellypics.com" target="_blank" class="text-primary"
          >Jellypic</a
        >
      </p>

      <p class="my-16"></p>

      <PhotoGallery
        ref="photoGallery"
        :container="container"
        :files="items"
        :currentPhoto="currentFile"
        :allowStarring="isAutheticated && share.allowStarring"
        :allowDownload="share.allowDownloads"
        :allowDelete="false"
        :stars="stars"
        @star="handleStarClick"
        @download="handleDownloadClick"
      ></PhotoGallery>
      <CollectionViewerTour ref="collectionViewerTour"></CollectionViewerTour>
    </template>
  </v-container>
</template>

<style scoped>
.deco-paragraph::first-line {
  margin-left: -20px; /* Moves the first line left */
}
.deco-paragraph {
  margin-left: 20px; /* Moves all lines right */
}

.divider {
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
  height: 1px;
}

.div-transparent:before {
  content: "";
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  width: 90%;
  height: 1px;
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(48, 49, 51, 0.445),
    transparent
  );
}

.div-arrow-down:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -7px;
  left: calc(50% - 7px);
  width: 14px;
  height: 14px;
  transform: rotate(45deg);
  background-color: white;
  border-bottom: 1px solid rgb(48, 49, 51);
  border-right: 1px solid rgb(48, 49, 51);
}

.div-tab-down:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: calc(50% - 10px);
  width: 20px;
  height: 14px;
  background-color: white;
  border-bottom: 1px solid rgb(48, 49, 51);
  border-left: 1px solid rgb(48, 49, 51);
  border-right: 1px solid rgb(48, 49, 51);
  border-radius: 0 0 8px 8px;
}

.div-stopper:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -6px;
  left: calc(50% - 7px);
  width: 14px;
  height: 12px;
  background-color: white;
  border-left: 1px solid rgb(48, 49, 51);
  border-right: 1px solid rgb(48, 49, 51);
}

.div-dot:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -9px;
  left: calc(50% - 9px);
  width: 18px;
  height: 18px;
  background-color: goldenrod;
  border: 1px solid rgb(48, 49, 51);
  border-radius: 50%;
  box-shadow:
    inset 0 0 0 2px white,
    0 0 0 4px white;
}

.soft-drop-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(0, 0, 0, 0.1);
}
</style>
