<template>
  <v-container class="my-16 py-16">
    <h2 class="text-h2 text-center" id="terms-index">All things legal</h2>
    <p></p>

    <h3 class="text-h3" id="privacy-policy">Privacy Policy</h3>
    <p>
      Please visit our
      <router-link to="/privacy">Privacy Policy</router-link> page for more
      details.
    </p>
    <h3 class="text-h3">Third party notices</h3>
    <p>
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy" target="_blank"
        >Privacy Policy</a
      >
      and
      <a href="https://policies.google.com/terms" target="_blank"
        >Terms of Service</a
      >
      apply.
    </p>

    <h3 class="text-h3">Use of Google Analytics 4 (GA4) on Jellypics.com</h3>
    <p>
      Jellypics.com uses Google Analytics 4 (GA4) to analyze website traffic and
      understand how visitors interact with our site. These insights help us
      enhance website functionality and improve your overall user experience.
    </p>
    <p class="my-4">
      Google Analytics 4 collects data about your interactions on the site
      (e.g., pages visited, links clicked), along with device information like
      browser type and operating system. It may also gather general location
      data (if you have consented to share it) to help us better tailor the
      content to our audience.
    </p>
    <p>
      Google Analytics may use cookies to distinguish between unique visitors
      and sessions, and it employs anonymized data processing techniques to
      protect your privacy. This means that information collected is aggregated
      or anonymized, ensuring no personally identifiable information is stored
      or shared.
    </p>
    <p>
      For more details on how Google handles data, please review Google’s
      <a
        href="https://policies.google.com/privacy"
        target="_blank"
        rel="noopener noreferrer"
        >Privacy Policy</a
      >
      and the
      <a
        href="https://marketingplatform.google.com/about/analytics/terms/us/"
        target="_blank"
        rel="noopener noreferrer"
        >Google Analytics Terms of Service</a
      >.
    </p>

    <h3 class="text-h3" id="release-history">Release history and change log</h3>
    <p>List of app releases and changes.</p>

    <v-list class="my-8">
      <v-list-item>
        <template v-slot:prepend>
          <v-icon icon="mdi-link-lock"></v-icon>
        </template>
        <v-list-item-title
          ><b>V 1.0.0</b> Beta 1 - Feburary 1<sup>st</sup>
          2025</v-list-item-title
        >
        <v-list-item-subtitle
          >First release to the public - VN only</v-list-item-subtitle
        >
      </v-list-item>
    </v-list>

    <h3 class="text-h3">Current Plan Limits</h3>
    <p>
      To ensure a smooth and enjoyable experience for all our users, we've
      implemented various anti-abuse systems. 🚀
    </p>

    <p>
      The first measure is a strict cap on the maximum number of users we can
      accept during these early stages of the app. This helps us prevent misuse
      and carefully audit the platform from both security and cost perspectives.
      🔒💰
    </p>

    <h4 class="text-h4">Platform Limitations</h4>
    <p>
      Below are some of the current limits that might impact your experience
      while using the platform. 🛠️
    </p>
    <p>
      <b>Please note:</b> These limits are temporary and will evolve as we grow!
    </p>
    <v-list density="dense" class="my-8">
      <v-list-item v-for="(value, key) in hardPlan" :key="key">
        <v-list-item-title>
          <b>{{ key }}</b
          >: {{ value === 0 ? "Not defined" : value }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getHardPlan } from "@/api/sales.client";

const hardPlan = ref(null);

const fetchHardPlan = async () => {
  try {
    const response = await getHardPlan();
    hardPlan.value = response.data;
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      console.error("Error fetching hard plan:", error);
    }
    throw error;
  }
};

onMounted(() => {
  fetchHardPlan();
});
</script>

<style scoped>
h2,
h3,
h4 {
  margin-top: 32px;
}
</style>
