<template>
  <v-container>
    <v-row dense>
      <v-col cols="6" class="d-flex flex-column">
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          :label="t('container-browser-head.search')"
          variant="plain"
          width="300"
          rounded
          clearable
          hint="Search by name"
        ></v-text-field>
      </v-col>
      <v-col
        cols="6"
        class="d-flex justify-end align-center"
        id="container-browser-head-actions"
      >
        <v-btn
          variant="plain"
          density="comfortable"
          @click="toggleShowOnlyStarred"
          :color="showOnlyStarred ? 'error' : ''"
          id="container-browser-head-actions-starred"
          v-tooltip.bottom="
            t('container-browser-head.show-liked-pictures-tooltip')
          "
        >
          <v-icon>{{
            showOnlyStarred ? "mdi-heart" : "mdi-heart-outline"
          }}</v-icon>
        </v-btn>

        <v-btn
          variant="plain"
          density="comfortable"
          @click="toggleSortOrder"
          v-if="viewMode === 'grid'"
          id="container-browser-head-actions-sort"
          v-tooltip.bottom="t('container-browser-head.toggle-sorting-tooltip')"
        >
          <v-icon>{{
            sortOrder === "asc" ? "mdi-sort-ascending" : "mdi-sort-descending"
          }}</v-icon>
        </v-btn>

        <v-btn
          density="comfortable"
          variant="plain"
          @click="toggleViewMode"
          id="container-browser-head-actions-view-mode"
          v-tooltip.bottom="
            t('container-browser-head.toggle-view-mode-tooltip')
          "
        >
          <v-icon>{{
            viewMode === "list" ? "mdi-view-grid" : "mdi-view-list"
          }}</v-icon>
        </v-btn>

        <v-menu max-width="250px">
          <template #activator="{ props }">
            <v-btn
              density="comfortable"
              variant="plain"
              v-bind="props"
              id="container-browser-head-actions-menu"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="openGallery">
              <template v-slot:prepend>
                <v-icon icon="mdi-image"></v-icon>
              </template>
              <v-list-item-title>{{
                t("container-browser-head.open-gallery")
              }}</v-list-item-title>
            </v-list-item>

            <v-list-item @click="toggleShowFileNames">
              <template v-slot:prepend>
                <v-icon
                  :icon="
                    showFileNames
                      ? 'mdi-checkbox-marked'
                      : 'mdi-checkbox-blank-outline'
                  "
                ></v-icon>
              </template>
              <v-list-item-title>{{
                t("container-browser-head.show-file-names")
              }}</v-list-item-title>
            </v-list-item>

            <v-list-item @click="copyFileNames">
              <template v-slot:prepend>
                <v-icon icon="mdi-content-copy"></v-icon>
              </template>
              <v-list-item-title>{{
                t("container-browser-head.copy-file-names")
              }}</v-list-item-title>
            </v-list-item>

            <v-list-item @click="copyLikedFileNames">
              <template v-slot:prepend>
                <v-icon icon="mdi-heart"></v-icon>
              </template>
              <v-list-item-title>{{
                t("container-browser-head.copy-liked-file-names")
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { defineModel, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import { useDisplay } from "vuetify";
import { ref } from "vue";

const { t } = useI18n();
const { xs, sm } = useDisplay();

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
});

const showOnlyStarred = defineModel("showOnlyStarred", {
  type: Boolean,
});

const search = defineModel("search", {
  type: String,
});

const sortOrder = defineModel("sortOrder", {
  type: String,
  default: "asc",
});

const viewMode = defineModel("viewMode", {
  type: String,
  default: "grid",
});

const showFileNames = defineModel("showFileNames", {
  type: Boolean,
  default: false,
});

const toggleSortOrder = () => {
  sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
};

const toggleViewMode = () => {
  viewMode.value = viewMode.value === "grid" ? "list" : "grid";
};

const toggleShowOnlyStarred = () => {
  showOnlyStarred.value = !showOnlyStarred.value;
};

const openGallery = () => {
  if (process.env.NODE_ENV !== "production") {
    console.log("Opening gallery...");
  }
  // Fire the openGallery event
  const event = new CustomEvent("openGallery");
  window.dispatchEvent(event);
};

const toggleShowFileNames = () => {
  showFileNames.value = !showFileNames.value;
  if (process.env.NODE_ENV !== "production") {
    console.log("Toggled show file names:", showFileNames.value);
  }
};

const copyFileNames = () => {
  if (process.env.NODE_ENV !== "production") {
    console.log("Copying file names...");
  }
  // Fire the copyFileNames event
  const event = new CustomEvent("copyFileNames");
  window.dispatchEvent(event);
};

const copyLikedFileNames = () => {
  if (process.env.NODE_ENV !== "production") {
    console.log("Copying liked file names...");
  }
  // Fire the copyLikedFileNames event
  const event = new CustomEvent("copyLikedFileNames");
  window.dispatchEvent(event);
};
</script>

<style scoped>
/* Empty style */
</style>
