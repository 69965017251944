<template>
  <v-dialog v-model="isOpen" transition="dialog-bottom-transition" fullscreen>
    <v-card class="overflow-hidden">
      <v-toolbar dense floating>
        <template v-slot:prepend>
          <v-btn v-if="allowDelete" icon="mdi-delete" @click="handleDelete" />
          <v-btn
            v-if="allowDownload"
            icon="mdi-download"
            @click="handleDownload"
          />
          <v-btn icon="mdi-fullscreen" @click="handleFullScreen"></v-btn>
        </template>

        <v-toolbar-title v-if="container && currentFile" class="text-center">
          <span v-if="!(xs || sm)">{{ container.name }} &mdash;</span>

          {{ currentFile.name }}</v-toolbar-title
        >

        <template v-slot:append>
          <v-btn icon="mdi-close" @click="isOpen = false"></v-btn>
        </template>
      </v-toolbar>
      <v-carousel
        v-model="model"
        class="fill-height"
        show-arrows="hover"
        :hide-delimiters="true"
      >
        <v-carousel-item
          v-for="file in files"
          :key="file.id"
          content-class="d-flex align-center justify-center"
        >
          <v-img
            :src="imageUrl(file.path)"
            :style="imageStyle"
            @wheel="onWheel"
            @mousedown="onMouseDown"
            @mousemove="onMouseMove"
            @mouseup="onMouseUp"
            @mouseleave="onMouseUp"
            @dblclick="resetImage"
          >
          </v-img>
        </v-carousel-item>
      </v-carousel>
      <!-- Thumbnails -->
      <v-container
        v-if="showThumbnails"
        class="thumbnailBar"
        :style="thumbnailBarStyle"
      >
        <v-img
          v-for="file in files"
          :key="file.id"
          :src="imageUrl(file.path, 300)"
          :srcset="generateImageSrcSet(file.path)"
          :sizes="generateImageSizes()"
          @click="model = files.indexOf(file)"
          :class="[
            'thumbnail',
            { 'selected-thumbnail': model === files.indexOf(file) },
          ]"
        />
      </v-container>

      <v-container
        class="d-flex pa-0 ma-0 align-center w-100"
        :style="statusbar"
      >
        <v-btn
          variant="plain"
          density="comfortable"
          size="small"
          icon="mdi-view-carousel"
          @click="showThumbnails = !showThumbnails"
        ></v-btn>

        <v-divider vertical class="mx-2"></v-divider>

        <ConfettiExplosion
          v-if="visible"
          :force="0.1"
          :colors="[
            'rgba(255, 105, 180, 1)', // Hot Pink
            'rgba(255, 165, 0, 1)', // Orange
            'rgba(255, 255, 0, 1)', // Yellow
            'rgba(0, 255, 127, 1)', // Spring Green
            'rgba(0, 191, 255, 1)', // Deep Sky Blue
          ]"
          :duration="4000"
          :particleSize="2"
          :particleCount="50"
          :stageHeight="600"
          :stageWidth="300"
        />
        <v-btn
          v-if="allowStarring"
          variant="plain"
          density="comfortable"
          size="small"
          :icon="isStarred() ? 'mdi-heart' : 'mdi-heart-outline'"
          @click="handleStarClick"
        />

        <template v-if="!(xs || sm)">
          <v-divider vertical class="mx-2"></v-divider>
          <v-icon size="small" class="ml-4 mr-2" color="grey"
            >mdi-image-size-select-actual</v-icon
          >
          <span class="text-caption" style="width: 190px"
            >{{ imageHeight }}×{{ imageWidth }} ({{
              imageSizeInMPixels
            }}
            MP)</span
          >
          <v-icon size="small" class="ml-4 mr-2" color="grey"
            >mdi-floppy</v-icon
          >
          <span class="text-caption" style="width: 90px" size="small">{{
            currentPhoto.sizeAsString
          }}</span>
        </template>
        <v-divider vertical class="mx-2"></v-divider>
        <v-icon size="small" class="ml-4" color="grey">mdi-magnify-plus</v-icon>
        <span class="text-caption mr-2 percentage-label" size="small"
          >{{ sliderValue }}%</span
        >
        <v-slider
          v-model="scale"
          :min="0.5"
          :max="3"
          :step="0.1"
          density="compact"
          class="small-slider"
          color="grey"
          hide-details
        ></v-slider>
        <v-btn
          v-if="scale !== 1"
          variant="plain"
          density="comfortable"
          size="small"
          icon="mdi-refresh-circle"
          @click="resetImage"
        >
        </v-btn>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { imageUrl } from "@/api/image.client";
import { getAllBlockMetadata } from "@/api/block.client";
import {
  ref,
  watch,
  computed,
  onMounted,
  defineModel,
  defineExpose,
  defineEmits,
  nextTick,
  getCurrentInstance,
} from "vue";
import { useTheme, useDisplay } from "vuetify";
import ConfettiExplosion from "vue-confetti-explosion";
import {
  generateImageSizes,
  generateImageSrcSet,
} from "@/helpers/image.helper";

const { xs, sm } = useDisplay();
const theme = useTheme();

const currentPhoto = defineModel("currentPhoto", {
  type: Object,
  default: {},
});

const container = defineModel("container", {
  type: Object,
  default: {},
});

const files = defineModel("files", {
  type: Array,
  default: () => [],
});

const props = defineProps({
  allowStarring: {
    type: Boolean,
    default: true,
  },
  allowDelete: {
    type: Boolean,
    default: true,
  },
  allowDownload: {
    type: Boolean,
    default: true,
  },
  stars: {
    type: Array,
    required: true,
  },
});

const isOpen = ref(false);
const model = ref(0);
const showThumbnails = ref(true);
const currentFile = ref(currentPhoto);
const visible = ref(false);
const pictureMetadata = ref(null);
const imageHeight = ref(0);
const imageWidth = ref(0);
const imageSizeInMPixels = computed(() => {
  if (imageHeight.value && imageWidth.value) {
    return ((imageHeight.value * imageWidth.value) / 1000000).toFixed(2);
  } else {
    return 0;
  }
});

// Get access at the this. objects
const { appContext } = getCurrentInstance();
// eslint-disable-next-line no-unused-vars
const global = appContext.config.globalProperties;

const emit = defineEmits(["star", "delete", "download"]);

watch(currentPhoto, (newVal, oldVal) => {
  model.value = files.value.findIndex(
    (file) => file.id === currentPhoto.value.id,
  );

  loadMetadata();

  console.info("Gallery photo changed from/to", oldVal, newVal);
});

watch(model, (newIndex) => {
  currentFile.value = files.value[newIndex];
});

const statusbar = computed(() => {
  return {
    backgroundColor:
      theme.name.value == "dark"
        ? "rgba(0, 0, 0, 0.3)"
        : "rgba(255, 255, 255, 0.8)",
    backdropFilter: "blur(16px)",
    "max-width": "unset",
  };
});

const thumbnailBarStyle = computed(() => {
  return {
    backgroundColor:
      theme.name.value == "dark"
        ? "rgba(0, 0, 0, 0.5)"
        : "rgba(255, 255, 255, 0.5)",
    backdropFilter: "blur(16px)",
  };
});

const scale = ref(1);
const sliderValue = ref(100);
const isDragging = ref(false);
const startX = ref(0);
const startY = ref(0);
const translateX = ref(0);
const translateY = ref(0);

watch(scale, (newValue) => {
  sliderValue.value = (newValue * 100).toFixed(0);
});

watch(files, (newValue) => {
  if (newValue.length === 0) {
    isOpen.value = false;
  }
});

const imageStyle = computed(() => ({
  transform: `scale(${scale.value}) translate(${translateX.value}px, ${translateY.value}px)`,
  transition: isDragging.value ? "none" : "transform 0.2s",
}));

const loadMetadata = async () => {
  if (!currentPhoto.value.id) {
    return;
  }

  getAllBlockMetadata(currentPhoto.value.block)
    .then((result) => {
      pictureMetadata.value = result.data;

      const heightMetadata = pictureMetadata.value.find(
        (meta) => meta.keyName === "Image Height",
      );
      const widthMetadata = pictureMetadata.value.find(
        (meta) => meta.keyName === "Image Width",
      );

      if (heightMetadata) {
        imageHeight.value = parseInt(heightMetadata.keyValue);
      }
      if (widthMetadata) {
        imageWidth.value = parseInt(widthMetadata.keyValue);
      }
    })
    .catch((ex) => {
      console.error("Error loading the picture metadata.", ex);
      throw ex;
    });
};

const onWheel = (event) => {
  const delta = event.deltaY > 0 ? -0.1 : 0.1;
  scale.value = Math.min(Math.max(scale.value + delta, 0.5), 3); // Limit zoom scale between 0.5 and 3
};

const onMouseDown = (event) => {
  event.preventDefault(); // Prevent the default drag behavior
  isDragging.value = true;
  startX.value = event.clientX - translateX.value;
  startY.value = event.clientY - translateY.value;
};

const onMouseMove = (event) => {
  if (isDragging.value) {
    translateX.value = event.clientX - startX.value;
    translateY.value = event.clientY - startY.value;
  }
};

const onMouseUp = () => {
  isDragging.value = false;
};

const resetImage = () => {
  scale.value = 1;
  translateX.value = 0;
  translateY.value = 0;
};

const open = () => {
  isOpen.value = true;
  visible.value = false;
};

const handleDelete = () => {
  const selectedFile = files.value[model.value];
  if (selectedFile) {
    emit("delete", selectedFile);
  }
};

const handleDownload = () => {
  const selectedFile = files.value[model.value];
  if (selectedFile) {
    emit("download", selectedFile);
  }
};

const handleFullScreen = () => {
  const selectedFile = files.value[model.value];
  if (selectedFile) {
    const urlToOpen = imageUrl(selectedFile.path);
    window.open(urlToOpen, "_blank");
  }
};

const handleStarClick = async () => {
  const selectedFile = files.value[model.value];
  if (selectedFile) {
    if (!isStarred()) {
      visible.value = false;
      await nextTick();
      visible.value = true;
    }
    emit("star", selectedFile);
  }
};

const isStarred = () => {
  const selectedFile = files.value[model.value];
  if (selectedFile) {
    const blockId = selectedFile.block;
    if (props.stars.length > 0) {
      return props.stars.find((s) => s.starredBlock === blockId) !== undefined;
    }

    return false;
  }

  return false;
};

// Expose the methods
defineExpose({
  open,
});

onMounted(() => {
  loadMetadata();
});
</script>

<style scoped>
.small-slider {
  max-width: 150px;
  padding: 0;
  z-index: 20;
}
.percentage-label {
  width: 38px;
  text-align: right;
  display: inline-block;
}
.thumbnailBar {
  display: flex;
  flex-direction: row; /* Ensure horizontal layout */
  overflow-x: scroll; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Disable vertical overflow */

  position: absolute;

  z-index: 10;
  bottom: 32px;
  max-width: 100%;
  width: 100%;
  height: 15vh;
  margin-left: auto;
  scrollbar-width: thin; /* For modern browsers to reduce scrollbar size */
}

.thumbnailBar::-webkit-scrollbar {
  height: 6px; /* Adjust scrollbar height */
}

.thumbnailBar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5); /* Style scrollbar thumb */
  border-radius: 4px;
}

.thumbnailBar::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Style scrollbar track */
}

.thumbnail {
  cursor: pointer;
  opacity: 0.6;
  transition:
    opacity 0.3s,
    transform 0.2s;
  border: 2px solid transparent;
  flex-shrink: 0; /* Prevent shrinking */
  width: 15vh; /* Fixed width for horizontal layout */
  height: 80px; /* Set height for consistency */
  margin-right: 8px; /* Space between thumbnails */
}

/* Keep existing hover and selected styles */
.thumbnail:hover {
  opacity: 1;
  z-index: 1000;
  transform: scale(1.5);
  transition:
    opacity 0.5s ease-in-out,
    transform 0.5s ease-in-out;
}

.selected-thumbnail {
  opacity: 1 !important;
  border-radius: 8px;
  border: 1px dashed rgba(230, 0, 255, 0.4);
  background-color: rgba(230, 0, 255, 0.1);
}
.selected-thumbnail:hover {
  opacity: 1 !important;
  border-radius: 8px;
  border: 1px transparent;
  background-color: transparent;
  transition:
    border 1s ease-in-out,
    background-color 1s ease-in-out;
}
</style>
