import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import globalProperties, {
  $mainContainerDispayName,
} from "./plugins/globalProperties";
import googleLoginPlugin from "./plugins/google-login/plugin";
import "@/assets/main.scss";
import { configureI18n } from "./i18n/utils";
import { createPinia } from "pinia";
import { useAuthStore } from "./stores/auth.store";
import { configureAxiosInstances } from "./api";
import VuetifyDialogPromise from "vuetify-dialog-promise";
import { VueReCaptcha } from "vue-recaptcha-v3";
import "shepherd.js/dist/css/shepherd.css";

import * as Sentry from "@sentry/vue";

loadFonts();
startApp();

async function startApp() {
  const app = createApp(App);

  try {
    Sentry.init({
      app,
      dsn: "https://59241f97ef10cdd795d4e712e5765427@o4509004007997440.ingest.de.sentry.io/4509004013830224",
    });
    console.info("🛰️Sentry initialized successfully.");
  } catch (error) {
    console.error("🛰️Error initializing Sentry:", error);
  }

  // Register global properties
  app.config.globalProperties.$mainContainerDispayName =
    $mainContainerDispayName;
  app.config.globalProperties.$mainObjectDispayName = $mainContainerDispayName;

  app.use(globalProperties);

  console.info(`
    
    Starting ${app.config.globalProperties.$appName} app in ${process.env.NODE_ENV} mode.
    v. ${app.config.globalProperties.$appVersion} - ${app.config.globalProperties.$appMilestone}

     •  ┓  •        ┓  ┏   ┓           
    ┏┓┏┓┃┏ ┓╋  ┏┓┏┓┏┫  ╋┓┏┏┃┏  ┓┏┏┓┓┏  
    ┛┗┛┗┛┗•┗┗  ┗┻┛┗┗┻  ┛┗┻┗┛┗  ┗┫┗┛┗┻  
                                ┛        

    Copyright © 2024-Present by Umberto Giacobbi & Co.
    www.umnbertogiacobbi.biz
  `);

  app.use(createPinia());
  app.use(router);
  app.use(vuetify);
  app.use(configureI18n());
  // https://github.com/cloudlace/vuetify-dialog-promise#readme
  app.use(VuetifyDialogPromise, {
    snackbarX: "center",
    snackbarY: "bottom",
    acceptText: "Ok",
    snackbarParent: "main-app-container",
  });

  // Check if environment variables are set
  const googleClientId = process.env.VUE_APP_GOOGLE_CLIENT_ID;
  const recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY;

  if (!googleClientId || !recaptchaSiteKey) {
    console.error(
      "🟥 Some configuration are not set, this prevents the app to run.",
    );
  } else {
    console.info("🟢 All configuration are set, the app is ready to run.");
  }

  // To do, one day this should go in the config
  app.use(googleLoginPlugin, {
    clientId: googleClientId,
  });

  app.use(VueReCaptcha, {
    siteKey: recaptchaSiteKey, // Sostituisci con la tua site key
  });

  try {
    const authStore = useAuthStore();
    authStore.loadDataFromLocalStorage();
    if (authStore.hasRefreshToken) {
      await authStore.refreshTokenAsync();
    }
  } catch {
    // catch error to start app on success or failure
  }

  configureAxiosInstances();

  app.mount("#app");
}
