<template>
  <v-container class="my-16 py-16">
    <h2 class="text-center text-h2">Our services current status</h2>

    <v-container id="service-status" class="d-flex justify-center align-center">
      <div class="gooey d-flex justify-center align-center">
        <div style="color: green; z-index: 1000">
          <span class="loading-dots"
            ><span>.</span><span>.</span><span>.</span></span
          >working<span class="loading-dots"
            ><span>.</span><span>.</span><span>.</span></span
          >
        </div>
      </div>
      <div class="text-caption">
        <!-- Existing build date -->
        <span id="status-message">Service statuses are being checked...</span>

        <!-- New Service Health Section -->
        <ul id="services">
          <li
            data-url="https://filevault.graybay-4c50acea.northeurope.azurecontainerapps.io/health"
          >
            Storage spaces &mdash; <span class="status">Checking...</span>
          </li>
          <li
            data-url="https://oauth.graybay-4c50acea.northeurope.azurecontainerapps.io/health"
          >
            Security systems &mdash; <span class="status">Checking...</span>
          </li>
          <li
            data-url="https://sales.graybay-4c50acea.northeurope.azurecontainerapps.io/health"
          >
            Communications &mdash; <span class="status">Checking...</span>
          </li>
          <li
            data-url="https://sinkit.graybay-4c50acea.northeurope.azurecontainerapps.io/health"
          >
            Base services &mdash; <span class="status">Checking...</span>
          </li>
          <li
            data-url="https://agents.graybay-4c50acea.northeurope.azurecontainerapps.io/health"
          >
            Agents &mdash; <span class="status">Checking...</span>
          </li>
        </ul>
      </div>
    </v-container>
  </v-container>
</template>

<script setup>
import { onMounted } from "vue";

onMounted(() => {
  const services = document.querySelectorAll("#services li");
  let completedChecks = 0;

  services.forEach((service) => {
    const url = service.getAttribute("data-url");
    const statusSpan = service.querySelector(".status");

    fetch(url)
      .then((response) => {
        if (response.ok) {
          statusSpan.textContent = "Healthy ✔️";
          statusSpan.classList.remove("unhealthy");
          statusSpan.classList.add("healthy");

          // Derive the buildTimestamp endpoint by replacing '/health' (case-insensitive) with '/Health/buildTimestamp'
          const buildUrl = url.replace(/health$/i, "Health/buildTimestamp");
          // Now call the buildTimestamp endpoint
          fetch(buildUrl)
            .then((response) => response.text())
            .then((buildInfo) => {
              // Create a new span to display the build info next to the status
              const buildInfoSpan = document.createElement("span");
              buildInfoSpan.style.marginLeft = "8px";
              buildInfoSpan.textContent = `${buildInfo}`;
              statusSpan.parentNode.appendChild(buildInfoSpan);
            })
            .catch((error) => {
              if (process.env.NODE_ENV !== "production") {
                console.error("Error fetching build timestamp:", error);
              }
            });
        } else {
          statusSpan.textContent = "Unhealthy ✖️";
          statusSpan.classList.remove("healthy");
          statusSpan.classList.add("unhealthy");
        }
      })
      .catch((error) => {
        statusSpan.textContent = "Error ✖️";
        statusSpan.classList.remove("healthy");
        statusSpan.classList.add("unhealthy");
      })
      .finally(() => {
        completedChecks++;
        if (completedChecks === services.length) {
          document.getElementById("status-message").textContent =
            "Service statuses check completed";
        }
      });
  });
});
</script>

<style scoped>
.gooey {
  background-image: linear-gradient(120deg, #a8e6cf 0%, #dcedc1 100%);
  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
  width: 150px;
  height: 150px;
  animation: morph 3s linear infinite;
  transform-style: preserve-3d;
  outline: 1px solid transparent;
  will-change: border-radius;
  margin: 20px;
}
.gooey:before,
.gooey:after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
  box-shadow: 5px 5px 89px rgba(169, 204, 174, 0.21);
  will-change: border-radius, transform, opacity;
  animation-delay: 200ms;
  background-image: linear-gradient(
    120deg,
    rgba(168, 230, 207, 0.55) 0%,
    rgba(220, 237, 193, 0.89) 100%
  );
}
.gooey:before {
  animation: morph 3s linear infinite;
  opacity: 0.21;
  animation-duration: 1.5s;
}
.gooey:after {
  animation: morph 3s linear infinite;
  animation-delay: 400ms;
  opacity: 0.89;
  line-height: 120px;
  text-indent: -21px;
}
@keyframes morph {
  0%,
  100% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  34% {
    border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
  }
  50% {
    opacity: 0.89;
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  67% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
  }
}
@keyframes fadeIn {
  100% {
    transform: scale(1.03);
    opacity: 0;
  }
}

.loading-dots {
  display: inline-block;
  font-size: 24px;
  line-height: 1;
}
.loading-dots span {
  opacity: 0;
  animation: blink 1.5s infinite;
}
.loading-dots span:nth-child(1) {
  animation-delay: 0s;
}
.loading-dots span:nth-child(2) {
  animation-delay: 0.5s;
}
.loading-dots span:nth-child(3) {
  animation-delay: 1s;
}
@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* Styles for service statuses */
#services {
  list-style: none;
  padding: 0;
  margin: 8px 0 0 0;
}
#services li {
  margin: 0;
}
.status {
}
.healthy {
  color: green;
}
.unhealthy {
  color: red;
}
</style>
