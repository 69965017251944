<template>
  <v-footer :style="footer">
    <!-- v-container helps with responsive spacing. -->
    <v-container class="footer-wrapper">
      <v-row class="justify-space-between">
        <!-- Left section: brand/logo and short description -->
        <v-col cols="12" sm="12" md="4">
          <div class="d-flex justify-start">
            <AppLogo alwaysShowText></AppLogo>
          </div>
          <p class="mt-4">
            We want to make photo-sharing convenient and secure both for
            photographers and clients.
          </p>

          <h6 class="text-h6 my-3">Contacts</h6>
          <p><a href="mailto:hello@jellypic.com">hello@jellypic.com</a></p>
          <p>+1 424-414-2040</p>
        </v-col>

        <!-- Company links -->
        <v-col cols="12" sm="6" md="2">
          <h6 class="text-h6 mb-3">Company</h6>
          <v-btn variant="plain" :to="{ path: '/', hash: '#landing-features' }">
            {{ $t("full-screen-page.features") }}
          </v-btn>

          <v-btn variant="plain" :to="{ path: '/', hash: '#landing-about' }">
            {{ $t("full-screen-page.about") }}
          </v-btn>
          <v-btn variant="plain" :to="{ path: '/', hash: '#landing-contact' }">
            {{ $t("full-screen-page.contacts") }}
          </v-btn>
          <v-btn variant="plain" :to="{ path: '/privacy' }">
            {{ $t("full-screen-page.privacy") }}
          </v-btn>
          <v-btn variant="plain" :to="{ path: '/service-terms' }">
            {{ $t("full-screen-page.service-terms") }}
          </v-btn>
          <v-btn variant="plain" :to="{ path: '/health' }">
            {{ $t("full-screen-page.services-status") }}
          </v-btn>
        </v-col>

        <!-- Language links -->
        <v-col cols="12" sm="6" md="2">
          <h6 class="text-h6 mb-3">Language</h6>

          <v-btn
            v-for="locale in $i18n.availableLocales"
            :key="`locale-${locale}`"
            variant="plain"
            @click="setLocale(locale)"
          >
            {{ $t(`locale-selector.${locale}`) }}
          </v-btn>
        </v-col>

        <v-col
          cols="12"
          class="text-caption"
          v-if="splitImageCredits.length > 0"
        >
          {{ $t("app-footer.image-credits") }}
          <span v-for="(line, index) in splitImageCredits" :key="index"
            >{{ line }}<br
          /></span>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script setup>
import { onMounted, defineProps, computed } from "vue";
import { useTheme } from "vuetify";
import AppLogo from "@/components/AppLogo.vue";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();

const setLocale = (newLocale) => {
  locale.value = newLocale;
};

const theme = useTheme();

const props = defineProps({
  imageCredits: {
    type: String,
    required: false,
    default: "",
  },
});

const footer = computed(() => {
  return {
    backgroundColor:
      theme.name.value == "dark"
        ? "rgba(0, 0, 0, 0.3)"
        : "rgba(255, 255, 255, 0.8)",
    backdropFilter: "blur(16px)",
  };
});

/**
 * Split the credits string into up to three lines by certain delimiters.
 * 1) We recursively split by a list of delimiters (©, (, ), ,).
 * 2) We remove extra whitespace and empty lines.
 * 3) If we end up with more than 3 lines, we merge the last lines so that we only have 3 total.
 */
const splitImageCredits = computed(() => {
  const credits = props.imageCredits;
  if (!credits) return [];

  // Helper to recursively split a string by multiple delimiters.
  function multiSplit(str, delimiters) {
    if (delimiters.length === 0) {
      return [str];
    }
    const [delim, ...rest] = delimiters;
    // Split by the first delimiter
    let parts = str.split(delim);
    // Keep the delimiter at the end of each part except the last
    parts = parts.map((part, i) =>
      i < parts.length - 1 ? part + delim : part,
    );
    // Now split each resulting part by the remaining delimiters
    return parts.flatMap((part) => multiSplit(part, rest));
  }

  // Adjust this array to control which delimiters you want to split on.
  const delimiters = ["©", "(", ")", ","];
  let tokens = multiSplit(credits, delimiters);

  // Clean up whitespace, remove any empty strings
  tokens = tokens.map((token) => token.trim()).filter(Boolean);

  // If we have more than 3 lines, combine the extras into the second-to-last line
  while (tokens.length > 1) {
    tokens[tokens.length - 2] += " " + tokens.pop();
  }

  return tokens;
});

// life cycle Hooks
onMounted(() => {});
</script>

<style scoped>
/* Features section */
.footer-wrapper {
  @media (min-width: 960px) {
    margin-left: 10vw;
    margin-right: 10vw;
  }
  @media (max-width: 959px) {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
